var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-nav",
        { staticClass: "navbar", attrs: { pills: "" } },
        [
          _c(
            "b-navbar-brand",
            [
              _c(
                "b-nav-text",
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle.sidebar-main",
                          modifiers: { "sidebar-main": true },
                        },
                      ],
                    },
                    [_c("i", { staticClass: "fas fa-bars" })]
                  ),
                  _c(
                    "router-link",
                    { staticClass: "navTitle", attrs: { to: "/home" } },
                    [
                      _vm._v(_vm._s(_vm.$t("nav.logo"))),
                      _vm.subdomain !== "napa"
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("nav.free")))])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "navToggleButton",
              attrs: { disabled: _vm.loading, "data-item": "headshoot" },
              on: { click: _vm.checkProfileToggle },
            },
            [
              _c("div", { staticClass: "loading" }, [
                _vm.vxHeadshotDefault
                  ? _c("i", {
                      staticClass: "far fa-user",
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { "data-item": "headshoot" },
                    })
                  : _c("img", {
                      staticClass: "headShoot",
                      attrs: {
                        "data-item": "headshoot",
                        src: _vm.vxHeadshotImage,
                      },
                      on: { error: _vm.photoError },
                    }),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "profileBox",
                },
                [
                  _c("b-spinner", {
                    staticClass: "buttonLoad",
                    attrs: { small: "", label: "Loading...", variant: "light" },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            { staticClass: "shadow", attrs: { id: "navToggle" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loading,
                      expression: "!loading",
                    },
                  ],
                  staticClass: "profileGroup",
                  attrs: { "data-item": "headshoot" },
                },
                [
                  _c("li", { staticClass: "profile changeImage" }, [
                    _c(
                      "span",
                      {
                        staticClass: "imageBox",
                        on: {
                          click: function ($event) {
                            return _vm.$bvModal.show("uploadImageToggle")
                          },
                        },
                      },
                      [
                        _vm.vxHeadshotDefault
                          ? _c("i", { staticClass: "far fa-user" })
                          : _c("img", {
                              staticClass: "headShoot",
                              attrs: { src: _vm.vxHeadshotImage },
                            }),
                        _c("span", [_vm._v(_vm._s(_vm.$t("nav.edit")))]),
                      ]
                    ),
                    _c("span", [_c("p", [_vm._v(_vm._s(_vm.vxAccount))])]),
                  ]),
                  _vm.vxAccountLevel !== _vm.vxConstantsAccountLevel.admin
                    ? _c("b-dropdown-divider")
                    : _vm._e(),
                  _vm.vxAccountLevel !== _vm.vxConstantsAccountLevel.admin
                    ? _c(
                        "router-link",
                        {
                          staticClass: "profileclick",
                          attrs: { to: "/subscribePlan", tag: "li" },
                        },
                        [
                          _c("a", [
                            _vm._v(
                              _vm._s(_vm.$t("nav.plan")) +
                                " : " +
                                _vm._s(_vm.vxAccountPlan)
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.vxAccountLevel !== _vm.vxConstantsAccountLevel.admin
                    ? _c(
                        "router-link",
                        {
                          staticClass: "profileclick",
                          attrs: { to: "/storedPoints", tag: "li" },
                        },
                        [
                          _c("a", [
                            _vm._v(
                              _vm._s(_vm.$t("nav.deposit")) +
                                " : " +
                                _vm._s(_vm.deposit) +
                                " " +
                                _vm._s(_vm.$t("nav.point"))
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.business ||
                  _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.shop
                    ? _c(
                        "router-link",
                        {
                          staticClass: "profileclick",
                          attrs: { to: "/setTimezone", tag: "li" },
                        },
                        [
                          _c("a", [
                            _vm._v(
                              _vm._s(_vm.$t("nav.timezone")) +
                                ": " +
                                _vm._s(
                                  _vm.$store.state.accountInformation.timezone
                                )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.vxAccountShopId
                    ? _c(
                        "b-dropdown-group",
                        {
                          attrs: {
                            id: "dropdown-group-shop",
                            header: _vm.$t("nav.shop"),
                          },
                        },
                        [
                          _vm.vxAccountShopId
                            ? _c("li", { staticClass: "profile group" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("nav.id")) +
                                    " : " +
                                    _vm._s(_vm.vxAccountShopId) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.vxAccountLevel !==
                          _vm.vxConstantsAccountLevel.basic
                            ? _c("li", { staticClass: "profile group" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("nav.techNumber")) +
                                    " : " +
                                    _vm._s(_vm.techNumber) +
                                    " " +
                                    _vm._s(_vm.$t("nav.unit")) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm.vxAccountCompanyId
                    ? _c(
                        "b-dropdown-group",
                        {
                          attrs: {
                            id: "dropdown-group-shop",
                            header: _vm.$t("nav.business"),
                          },
                        },
                        [
                          _vm.vxAccountCompanyId
                            ? _c("li", { staticClass: "profile group" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("nav.id")) +
                                    " : " +
                                    _vm._s(_vm.vxAccountCompanyId) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.vxAccountLevel ===
                          _vm.vxConstantsAccountLevel.business
                            ? _c("li", { staticClass: "profile group" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("nav.shopNumber")) +
                                    " : " +
                                    _vm._s(_vm.techNumber) +
                                    " " +
                                    _vm._s(_vm.$t("nav.unit")) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _c("b-dropdown-divider"),
                  _c(
                    "router-link",
                    {
                      staticClass: "navItem",
                      attrs: { to: "/changePassword", tag: "li" },
                    },
                    [_c("a", [_vm._v(_vm._s(_vm.$t("nav.changePassword")))])]
                  ),
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "profileclick",
                      attrs: { dropleft: "", text: _vm.$t("nav.Language") },
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        { attrs: { id: "en" }, on: { click: _vm.en } },
                        [_vm._v("English")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "navItem",
                      attrs: { to: "/privacyPolicy", tag: "li" },
                    },
                    [
                      _c("a", { attrs: { target: "_blank" } }, [
                        _vm._v(_vm._s(_vm.$t("nav.PrivacyPolicy"))),
                      ]),
                    ]
                  ),
                  _c("li", { staticClass: "navItem" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;", id: "signOut" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fnSignOut.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("nav.SignOut")) + " ")]
                    ),
                  ]),
                  _c("b-dropdown-divider"),
                  _c(
                    "li",
                    {
                      staticClass: "profile",
                      staticStyle: { "text-align": "center" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("nav.version")) + " ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }