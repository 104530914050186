var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "frame" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "formHeader" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("batchCreate.title")))]),
        _c("p", [_vm._v(_vm._s(_vm.$t("batchCreate.subtitle")))]),
      ]),
      _c("div", [
        _c("div", { staticClass: "formBody" }, [
          _c(
            "div",
            { staticClass: "progressBar" },
            [
              _c(
                "b-progress",
                {
                  attrs: {
                    max: 100,
                    animated:
                      _vm.progress === 100 || _vm.upload.error ? false : true,
                    variant:
                      _vm.progress === 100
                        ? "success"
                        : _vm.upload.error
                        ? "danger"
                        : "info",
                  },
                },
                [
                  _c("b-progress-bar", {
                    attrs: { value: _vm.progress, label: `${_vm.progress}%` },
                  }),
                ],
                1
              ),
              _vm.doNothing
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("batchCreate.status.doNothing"))),
                  ])
                : _vm._e(),
              _vm.upload.active
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("batchCreate.status.active"))),
                  ])
                : _vm._e(),
              _vm.upload.success
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("batchCreate.status.success"))),
                  ])
                : _vm._e(),
              _vm.upload.error
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("batchCreate.status.error"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.upload.error
            ? _c("div", { staticClass: "invalidInput" }, [
                _vm.errorStatus.shopID || _vm.errorStatus.businessID
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.whatIdError) +
                          ": " +
                          _vm._s(_vm.id) +
                          " " +
                          _vm._s(_vm.$t("batchCreate.error.unit")) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.errorStatus.account
                  ? _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("batchCreate.error.account")) +
                          ": " +
                          _vm._s(_vm.account) +
                          " " +
                          _vm._s(_vm.$t("batchCreate.error.unit")) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.errorStatus.excel
                  ? _c("p", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("batchCreate.error.excel")) + " "
                      ),
                    ])
                  : _vm._e(),
                _vm.errorStatus.redCell
                  ? _c("p", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("batchCreate.error.excel")) + " "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c("hr", { staticClass: "formLine" }),
          _c("label", { staticClass: "inputButton w100p" }, [
            _c("input", {
              staticClass: "disabledButton",
              attrs: {
                disabled: _vm.loading,
                type: "file",
                accept:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              },
              on: { input: _vm.uploadfile },
            }),
            _c("p", { class: _vm.loading ? "disabled" : "" }, [
              _vm._v(" " + _vm._s(_vm.$t("button.selectFileAndUpload")) + " "),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }