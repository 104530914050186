var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "frame" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "formHeader" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("bill.title")))]),
      ]),
      _c(
        "div",
        { staticClass: "billBody" },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                "spinner-variant": "info",
                show: _vm.overlay,
                rounded: "sm",
                variant: "light",
                opacity: 0.6,
                blur: "none",
              },
            },
            [
              _c("div", { staticClass: "billTop" }, [
                _c("div", { staticClass: "dataPlan" }, [
                  _c("h4", [_vm._v(_vm._s(_vm.$t("bill.data")))]),
                  _c("p", [_vm._v(_vm._s(_vm.billDataPlan))]),
                ]),
                _c("div", { staticClass: "chargedPoint" }, [
                  _c("h4", [_vm._v(_vm._s(_vm.$t("bill.charged")))]),
                  _c("p", [_vm._v(_vm._s(_vm.chargedPoint))]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "goToStoredPoint" },
                [
                  _c(
                    "m-button",
                    {
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/storedPoints")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.goDeposit")) + " ")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "billBottom" }, [
                _vm.isBusinessAccount
                  ? _c("div", { staticClass: "businessAccount" }, [
                      _c(
                        "table",
                        { staticClass: "listTable" },
                        [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "billTitle",
                                attrs: { colspan: "3" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("bill.plan.shop")) + " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "billDescr",
                                attrs: { colspan: "3" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("bill.shopDescription")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _vm._l(_vm.shopList, function (list) {
                            return _c(
                              "tbody",
                              {
                                key: list.shopId,
                                attrs: { id: "shopListBody" },
                              },
                              [
                                _c("tr", { staticClass: "billTitle" }, [
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.$t("bill.table.shopID"))),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.$t("bill.table.desc"))),
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.$t("bill.table.unit"))),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("td", { attrs: { rowspan: "3" } }, [
                                    _vm._v(_vm._s(list.shopId)),
                                  ]),
                                  _c("td", { staticClass: "billDefault" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("bill.table.default"))
                                    ),
                                  ]),
                                  _c("td", { attrs: { rowspan: "1" } }, [
                                    _vm._v("5"),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("td", { staticClass: "billDefault" }, [
                                    _vm._v(_vm._s(_vm.$t("bill.table.extra"))),
                                  ]),
                                  _c("td", { attrs: { rowspan: "2" } }, [
                                    _vm._v(
                                      _vm._s(
                                        list.extraMember * _vm.shopExtraFee
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("tr", [
                                  _c("td", [_vm._v(_vm._s(list.extraMember))]),
                                ]),
                              ]
                            )
                          }),
                          _c("tr", { staticClass: "billSubTotal" }, [
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm._v(_vm._s(_vm.$t("bill.table.shopSubTotal"))),
                            ]),
                            _c("td", { attrs: { colspan: "1" } }, [
                              _vm._v(_vm._s(_vm.shopTotal)),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", {
                              staticStyle: { padding: "5px" },
                              attrs: { colspan: "3" },
                            }),
                          ]),
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "billTitle",
                                attrs: { colspan: "3" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("bill.plan.business")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "billDescr",
                                attrs: { colspan: "3" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("bill.businessDescription")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", { staticClass: "billTitle" }, [
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm._v(_vm._s(_vm.$t("bill.table.desc"))),
                            ]),
                            _c("td", { attrs: { colspan: "1" } }, [
                              _vm._v(_vm._s(_vm.$t("bill.table.unit"))),
                            ]),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "billDefault",
                                attrs: { colspan: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("bill.table.default")) +
                                    " "
                                ),
                              ]
                            ),
                            _c("td", [_vm._v("20")]),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "billDefault",
                                attrs: { colspan: "2" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("bill.table.extraShop")) +
                                    " "
                                ),
                              ]
                            ),
                            _c("td", { attrs: { rowspan: "2" } }, [
                              _vm._v(
                                _vm._s(_vm.extraMember * _vm.BuninessExtraFee)
                              ),
                            ]),
                          ]),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm._v(_vm._s(_vm.extraMember)),
                            ]),
                          ]),
                          _c("tr", { staticClass: "billSubTotal" }, [
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm._v(_vm._s(_vm.$t("bill.table.busSubTotal"))),
                            ]),
                            _c("td", { attrs: { colspan: "1" } }, [
                              _vm._v(_vm._s(_vm.businessTotal)),
                            ]),
                          ]),
                          _c("tr", { staticClass: "billTotal" }, [
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v(_vm._s(_vm.$t("bill.table.businessFee"))),
                            ]),
                            _c("th", { attrs: { colspan: "1" } }, [
                              _vm._v(_vm._s(_vm.chargedPoint)),
                            ]),
                          ]),
                        ],
                        2
                      ),
                      _c("ul", { staticClass: "billNote" }, [
                        _vm._v(" " + _vm._s(_vm.$t("bill.note.title")) + " "),
                        _c("li", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("bill.note.shopFee")) + " "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("bill.note.busFee")) + " "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("bill.note.business")) + " "
                          ),
                        ]),
                      ]),
                    ])
                  : _vm.isShopAccount
                  ? _c("div", { staticClass: "shopAccount" }, [
                      _c("table", { staticClass: "listTable" }, [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass: "billTitle",
                              attrs: { colspan: "3" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("bill.plan.shop")) + " "
                              ),
                            ]
                          ),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "billDescr",
                              attrs: { colspan: "3" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("bill.shopDescription")) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c("tr", { staticClass: "billTitle" }, [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("bill.table.desc"))),
                          ]),
                          _c("td", { attrs: { colspan: "1" } }, [
                            _vm._v(_vm._s(_vm.$t("bill.table.unit"))),
                          ]),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "billDefault",
                              attrs: { colspan: "2" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("bill.table.default")) + " "
                              ),
                            ]
                          ),
                          _c("td", [_vm._v("5")]),
                        ]),
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "billDefault",
                              attrs: { colspan: "2" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("bill.table.extra")) + " "
                              ),
                            ]
                          ),
                          _c("td", { attrs: { rowspan: "2" } }, [
                            _vm._v(_vm._s(_vm.extraMember * _vm.shopExtraFee)),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _vm._v(_vm._s(_vm.extraMember)),
                          ]),
                        ]),
                        _c("tr", { staticClass: "billTotal" }, [
                          _c("th", { attrs: { colspan: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("bill.table.fee"))),
                          ]),
                          _c("th", { attrs: { colspan: "1" } }, [
                            _vm._v(_vm._s(_vm.chargedPoint)),
                          ]),
                        ]),
                      ]),
                      _c("ol", { staticClass: "billNote" }, [
                        _vm._v(" " + _vm._s(_vm.$t("bill.note.title")) + " "),
                        _vm.vxAccountCompanyId
                          ? _c("li", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("bill.note.shopCom")) + " "
                              ),
                            ])
                          : _c("li", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("bill.note.shopFee")) + " "
                              ),
                            ]),
                      ]),
                    ])
                  : _vm.isBasicAccount
                  ? _c("div", { staticClass: "basicAccount" }, [
                      _c("table", { staticClass: "listTable" }, [
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticClass: "billTitle",
                              attrs: { colspan: "2" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("bill.plan.basic")) + " "
                              ),
                            ]
                          ),
                        ]),
                        _c("tr", { staticClass: "billTitle" }, [
                          _c("td", [_vm._v(_vm._s(_vm.$t("bill.table.desc")))]),
                          _c("td", [_vm._v(_vm._s(_vm.$t("bill.table.unit")))]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "billDefault" }, [
                            _vm._v(_vm._s(_vm.$t("bill.table.default"))),
                          ]),
                          _c("td", [_vm._v("0")]),
                        ]),
                        _c("tr", { staticClass: "billTotal" }, [
                          _c("th", [_vm._v(_vm._s(_vm.$t("bill.table.fee")))]),
                          _c("th", [_vm._v("0")]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }