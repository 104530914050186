var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "frame" }, [
    _c(
      "div",
      { staticClass: "testReportBottom" },
      [
        _c("dataTable", {
          attrs: {
            columns: _vm.columns,
            device: "signinLogs",
            "data-test": "dataTable",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }