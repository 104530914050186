var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "alertBox" }, [
      _c("i", {
        staticClass: "fas fa-info-circle info",
        attrs: { slot: "text" },
        slot: "text",
      }),
      _c("p", [_vm._v("部分文字尚未翻譯，目前此頁面只有英文版本")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }