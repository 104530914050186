var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("formFormat", {
        attrs: {
          page: _vm.page,
          formInput: _vm.formInput,
          loading: _vm.loading,
          error: _vm.errorMessage,
          "data-test": "formFormat",
        },
        on: {
          "update:error": function ($event) {
            _vm.errorMessage = $event
          },
          submit: _vm.showEmitValue,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "changePasswordSuccess",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
            title: _vm.$t("modal.success"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function () {
                return [
                  _c("h5", [_vm._v(_vm._s(_vm.$t("modal.success")))]),
                  _c("button", { on: { click: _vm.fnChangePasswordSuccess } }, [
                    _vm._v("X"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: { click: _vm.fnChangePasswordSuccess },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("button.ok")) + " "),
                      _vm.loading
                        ? _c("b-spinner", {
                            staticClass: "buttonLoad",
                            attrs: {
                              small: "",
                              label: "Loading...",
                              variant: "light",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.changePassword.success.text1"))),
          ]),
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.changePassword.success.text2"))),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "changePasswordError",
            centered: "",
            title: _vm.$t("modal.error"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(_vm._s(_vm.apiMessage))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }