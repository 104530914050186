var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "frame" },
    [
      _vm.showAlertMessage
        ? _c("div", { staticClass: "alertBox" }, [
            _c("i", {
              staticClass: "fas fa-info-circle info",
              attrs: { slot: "text" },
              slot: "text",
            }),
            (_vm.vxAccountLevel === _vm.vxConstantsAccountLevel.shop &&
              !_vm.vxAccountCompanyId) ||
            _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.business
              ? _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("invalidError.subscribe.haveGroup")) +
                      " "
                  ),
                ])
              : _c("p", [
                  _vm._v(_vm._s(_vm.$t("invalidError.subscribe.inAnyGruop"))),
                ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "formHeader" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("subscribePlan.title")))]),
        _c("p", [_vm._v(_vm._s(_vm.$t("subscribePlan.subtitle")))]),
      ]),
      _c("div", { staticClass: "wrap subscribePage" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.sizeOfPlan !== 0,
                expression: "sizeOfPlan !== 0",
              },
            ],
            staticClass: "left",
            on: { click: _vm.fnGoLeft },
          },
          [_c("i", { staticClass: "fas fa-chevron-left" })]
        ),
        _c("div", { staticClass: "planCover" }, [
          _c(
            "div",
            { staticClass: "planBody" },
            [
              _c(
                "b-col",
                { staticClass: "planBox", attrs: { id: "basicUser" } },
                [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.vxAccountPlan === _vm.vxConstantsDataPlan.basic,
                          expression:
                            "vxAccountPlan === vxConstantsDataPlan.basic",
                        },
                      ],
                      staticClass: "submit",
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("subscribePlan.subscribed")) + " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "planInnerBox" }, [
                    _c("h6", [
                      _vm._v(_vm._s(_vm.$t("subscribePlan.basic.title"))),
                    ]),
                    _c("div", { staticClass: "priceBox" }, [
                      _c("div", { staticClass: "basicPriceFixIE price" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("subscribePlan.basic.price")) +
                            " "
                        ),
                      ]),
                    ]),
                    (_vm.vxShopBefore && !_vm.vxAccountCompanyId) ||
                    _vm.vxBusinessBefore ||
                    (!_vm.vxAccountShopId && !_vm.vxAccountCompanyId)
                      ? _c(
                          "div",
                          [
                            _c(
                              "m-button",
                              {
                                staticClass: "tableButton",
                                class:
                                  _vm.vxAccountPlan !==
                                  _vm.vxConstantsDataPlan.basic
                                    ? "cancel"
                                    : "",
                                attrs: {
                                  disabled:
                                    _vm.vxAccountPlan ===
                                    _vm.vxConstantsDataPlan.basic,
                                  raised: "",
                                  outlined:
                                    _vm.vxAccountPlan ===
                                    _vm.vxConstantsDataPlan.basic,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showPlanModal("Basic")
                                  },
                                },
                              },
                              [
                                _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("button.subscribe")) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("hr"),
                    _c(
                      "div",
                      {
                        staticClass: "planContent",
                        attrs: { id: "basicUserContent" },
                      },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.basic.text1"))),
                          ]),
                          _c("li", [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.basic.text2"))),
                          ]),
                          _c("li", [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.basic.text3"))),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "dropBox",
                        on: { click: _vm.fnBasicDrop },
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-chevron-right",
                          attrs: { id: "basicDropdown" },
                        }),
                      ]
                    ),
                    _c("hr"),
                    _c("div", { staticClass: "planFooter" }, [
                      _c("ul", { staticClass: "iconGroup" }, [
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-table" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text2"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-chart-pie" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text3"))),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "planBox", attrs: { id: "proUser" } },
                [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.vxAccountPlan === _vm.vxConstantsDataPlan.shop,
                          expression:
                            "vxAccountPlan === vxConstantsDataPlan.shop",
                        },
                      ],
                      staticClass: "submit",
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("subscribePlan.subscribed")) + " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "planInnerBox" }, [
                    _c("h6", [
                      _vm._v(_vm._s(_vm.$t("subscribePlan.pro.title"))),
                    ]),
                    _c("div", { staticClass: "priceBox" }, [
                      _c("div", { staticClass: "price" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.proDefaultPrice) +
                            " " +
                            _vm._s(_vm.$t("subscribePlan.pro.priceSubTitle")) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "default mb20" }, [
                        _vm._v(
                          " (" +
                            _vm._s(_vm.$t("subscribePlan.pro.defaultManage")) +
                            " " +
                            _vm._s(_vm.proGroupProple) +
                            " " +
                            _vm._s(_vm.$t("subscribePlan.pro.memberUnit")) +
                            ") "
                        ),
                      ]),
                      _c("div", { staticClass: "bonus" }, [
                        _vm._v(_vm._s(_vm.$t("subscribePlan.pro.extra"))),
                      ]),
                      _c("div", { staticClass: "default" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("subscribePlan.pro.limit")) + " "
                        ),
                      ]),
                    ]),
                    (_vm.vxShopBefore && !_vm.vxAccountCompanyId) ||
                    _vm.vxBusinessBefore ||
                    (!_vm.vxAccountShopId && !_vm.vxAccountCompanyId)
                      ? _c("div", [
                          _vm.vxShopBefore
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "m-button",
                                    {
                                      staticClass: "tableButton cancel",
                                      attrs: { raised: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.startNewGroupFlow("Pro")
                                        },
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("button.createNew")) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "m-button",
                                    {
                                      staticClass: "tableButton cancel",
                                      attrs: {
                                        raised: "",
                                        disabled: _vm.loading,
                                        outlined: _vm.loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.restoreGroupFlow("Pro")
                                        },
                                      },
                                    },
                                    [
                                      _vm.loading
                                        ? _c(
                                            "p",
                                            [
                                              _c("b-spinner", {
                                                staticClass: "buttonLoad",
                                                attrs: {
                                                  small: "",
                                                  label: "Loading...",
                                                  variant: "light",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("button.restore")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "m-button",
                                    {
                                      staticClass: "tableButton cancel",
                                      attrs: {
                                        raised: "",
                                        outlined:
                                          _vm.vxAccountLevel ===
                                            _vm.vxConstantsAccountLevel.shop ||
                                          _vm.vxBusinessBefore,
                                        disabled:
                                          _vm.vxAccountLevel ===
                                            _vm.vxConstantsAccountLevel.shop ||
                                          _vm.vxBusinessBefore,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.subscribeFlow("Pro")
                                        },
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("button.subscribe")) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ])
                      : _vm._e(),
                    _c("hr"),
                    _c(
                      "div",
                      {
                        staticClass: "planContent",
                        attrs: { id: "proUserContent" },
                      },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("subscribePlan.pro.priceRule.text1")
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("subscribePlan.pro.priceRule.text2")
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("subscribePlan.pro.priceRule.text3")
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("subscribePlan.pro.priceRule.text4")
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("subscribePlan.pro.priceRule.text5")
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("subscribePlan.pro.priceRule.text6")
                              )
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "dropBox", on: { click: _vm.fnProDrop } },
                      [
                        _c("i", {
                          staticClass: "fas fa-chevron-right",
                          attrs: { id: "proDropdown" },
                        }),
                      ]
                    ),
                    _c("hr"),
                    _c("div", { staticClass: "planFooter" }, [
                      _c("ul", { staticClass: "iconGroup" }, [
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-print" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text1"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-table" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text2"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-chart-pie" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text3"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "far fa-chart-bar" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text4"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-user-friends" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text5"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-users-cog" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text6"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-database" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text7"))),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "planBox", attrs: { id: "enterpriseUser" } },
                [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.vxAccountPlan ===
                            _vm.vxConstantsDataPlan.business,
                          expression:
                            "vxAccountPlan === vxConstantsDataPlan.business",
                        },
                      ],
                      staticClass: "submit",
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("subscribePlan.subscribed")) + " "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "planInnerBox" }, [
                    _c("h6", [
                      _vm._v(_vm._s(_vm.$t("subscribePlan.enterprise.title"))),
                    ]),
                    _c("div", { staticClass: "priceBox" }, [
                      _c("div", { staticClass: "price" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.entDefaultPrice) +
                            " " +
                            _vm._s(
                              _vm.$t("subscribePlan.enterprise.priceSubTitle")
                            ) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "default mb20" }, [
                        _vm._v(
                          " (" +
                            _vm._s(
                              _vm.$t("subscribePlan.enterprise.defaultManage")
                            ) +
                            " " +
                            _vm._s(_vm.entGroupProple) +
                            " " +
                            _vm._s(
                              _vm.$t("subscribePlan.enterprise.memberUnit")
                            ) +
                            ") "
                        ),
                      ]),
                      _c("div", { staticClass: "bonus" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("subscribePlan.enterprise.extra")) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "default" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("subscribePlan.enterprise.limit")) +
                            " "
                        ),
                      ]),
                    ]),
                    (_vm.vxShopBefore && !_vm.vxAccountCompanyId) ||
                    _vm.vxBusinessBefore ||
                    (!_vm.vxAccountShopId && !_vm.vxAccountCompanyId)
                      ? _c("div", [
                          _vm.vxBusinessBefore
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "m-button",
                                    {
                                      staticClass: "tableButton cancel",
                                      attrs: { raised: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.startNewGroupFlow(
                                            "Enterprise"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("button.createNew")) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "m-button",
                                    {
                                      staticClass: "tableButton cancel",
                                      attrs: {
                                        raised: "",
                                        disabled: _vm.loading,
                                        outlined: _vm.loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.restoreGroupFlow(
                                            "Enterprise"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.loading
                                        ? _c(
                                            "p",
                                            [
                                              _c("b-spinner", {
                                                staticClass: "buttonLoad",
                                                attrs: {
                                                  small: "",
                                                  label: "Loading...",
                                                  variant: "light",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("p", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("button.restore")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "m-button",
                                    {
                                      staticClass: "tableButton cancel",
                                      attrs: {
                                        raised: "",
                                        outlined:
                                          _vm.vxAccountLevel ===
                                            _vm.vxConstantsAccountLevel
                                              .business || _vm.vxShopBefore,
                                        disabled:
                                          _vm.vxAccountLevel ===
                                            _vm.vxConstantsAccountLevel
                                              .business || _vm.vxShopBefore,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.subscribeFlow("Enterprise")
                                        },
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("button.subscribe")) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ])
                      : _vm._e(),
                    _c("hr"),
                    _c(
                      "div",
                      {
                        staticClass: "planContent",
                        attrs: { id: "BusinessUserContent" },
                      },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "subscribePlan.enterprise.priceRule.text1"
                                )
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "subscribePlan.enterprise.priceRule.text2"
                                )
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "subscribePlan.enterprise.priceRule.text3"
                                )
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "subscribePlan.enterprise.priceRule.text4"
                                )
                              )
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "subscribePlan.enterprise.priceRule.text5"
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "dropBox",
                        on: { click: _vm.fnBusinessDrop },
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-chevron-right",
                          attrs: { id: "BusinessDropdown" },
                        }),
                      ]
                    ),
                    _c("hr"),
                    _c("div", { staticClass: "planFooter" }, [
                      _c("ul", { staticClass: "iconGroup" }, [
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-print" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text1"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-table" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text2"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-chart-pie" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text3"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "far fa-chart-bar" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text4"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-user-friends" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text5"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-users-cog" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text6"))),
                          ]),
                        ]),
                        _c("li", { staticClass: "planIcon" }, [
                          _c("i", { staticClass: "fas fa-database" }),
                          _c("p", { staticClass: "iconInfo" }, [
                            _vm._v(_vm._s(_vm.$t("subscribePlan.icon.text7"))),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.sizeOfPlan > -62,
                expression: "sizeOfPlan > -62",
              },
            ],
            staticClass: "right",
            on: { click: _vm.fnGoRight },
          },
          [_c("i", { staticClass: "fas fa-chevron-right" })]
        ),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            id: "subscribePlan",
            size: "lg",
            centered: "",
            title: _vm.$t("modal.subscribeDetail"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.no")) + " ")]
                  ),
                  _vm.startNewGroup
                    ? _c(
                        "m-button",
                        {
                          staticClass: "tableButton cancel",
                          attrs: {
                            disabled: _vm.loading || _vm.subscribeError,
                            outlined: _vm.loading,
                            raised: "",
                            dense: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$bvModal.show("startNewGroupWarning")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("button.createNew")) + " "
                          ),
                          _vm.loading
                            ? _c("b-spinner", {
                                staticClass: "buttonLoad",
                                attrs: {
                                  small: "",
                                  label: "Loading...",
                                  variant: "light",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm.restoreGroup
                    ? _c(
                        "m-button",
                        {
                          staticClass: "tableButton cancel",
                          attrs: {
                            disabled: _vm.loading || _vm.subscribeError,
                            outlined: _vm.loading,
                            raised: "",
                            dense: "",
                          },
                          on: { click: _vm.startRestoreGorup },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("button.restore")) + " "),
                          _vm.loading
                            ? _c("b-spinner", {
                                staticClass: "buttonLoad",
                                attrs: {
                                  small: "",
                                  label: "Loading...",
                                  variant: "light",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "m-button",
                        {
                          staticClass: "tableButton cancel",
                          attrs: {
                            disabled: _vm.loading || _vm.subscribeError,
                            outlined: _vm.loading,
                            raised: "",
                            dense: "",
                          },
                          on: { click: _vm.fnSubscribe },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("button.subscribe")) + " "
                          ),
                          _vm.loading
                            ? _c("b-spinner", {
                                staticClass: "buttonLoad",
                                attrs: {
                                  small: "",
                                  label: "Loading...",
                                  variant: "light",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", { staticClass: "subscribeImportant" }, [
            _c(
              "div",
              {
                staticClass: "subscribePlan",
                class: _vm.selectPlan === "Basic" ? "w100p" : "",
              },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("modal.subscribe.planTitle"))),
                ]),
                _c("div", { staticClass: "select" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.planNameChange) +
                      " " +
                      _vm._s(_vm.$t("modal.subscribe.planSubTitle")) +
                      " "
                  ),
                ]),
              ]
            ),
            _vm.selectPlan === "Pro"
              ? _c("div", { staticClass: "subscribeMember" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("modal.subscribe.memberTitle"))),
                  ]),
                  _c("div", { staticClass: "select" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.proGroupProple) +
                        " " +
                        _vm._s(_vm.$t("modal.subscribe.memberSubTitle")) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.selectPlan === "Enterprise"
              ? _c("div", { staticClass: "subscribeMember" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("modal.subscribe.shopManagerTitle"))),
                  ]),
                  _c("div", { staticClass: "select" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.entGroupProple) +
                        " " +
                        _vm._s(_vm.$t("modal.subscribe.memberSubTitle")) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.restoreGroup
              ? _c("div", { staticClass: "subscribeMember extraMember" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("modal.subscribe.extraMemberTitle"))),
                  ]),
                  _c("div", { staticClass: "select" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.extraMember) +
                        " " +
                        _vm._s(_vm.$t("modal.subscribe.memberSubTitle")) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "subscribePoint" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("modal.subscribe.priceTitle"))),
              ]),
              _vm.selectPlan === "Pro"
                ? _c("div", { staticClass: "select" }, [
                    _vm.vxAccountCompanyId &&
                    _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.shop
                      ? _c("div", [
                          _c("p", [
                            _vm._v(
                              "0 " + _vm._s(_vm.$t("modal.subscribe.unit"))
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "modal.subscribe.info.enterprise.shopInComp"
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm.restoreGroup
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.totalCount) +
                              " " +
                              _vm._s(_vm.$t("modal.subscribe.unit")) +
                              " "
                          ),
                        ])
                      : _c("p", [
                          _vm._v(
                            _vm._s(_vm.proDefaultPrice) +
                              " " +
                              _vm._s(_vm.$t("modal.subscribe.unit"))
                          ),
                        ]),
                  ])
                : _vm.selectPlan === "Enterprise"
                ? _c("div", { staticClass: "select" }, [
                    _vm.restoreGroup
                      ? _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.totalCount) +
                              " " +
                              _vm._s(_vm.$t("modal.subscribe.unit")) +
                              " "
                          ),
                        ])
                      : _c("p", [
                          _vm._v(
                            _vm._s(_vm.entDefaultPrice) +
                              " " +
                              _vm._s(_vm.$t("modal.subscribe.unit"))
                          ),
                        ]),
                  ])
                : _c("div", { staticClass: "select" }, [
                    _vm._v(_vm._s(_vm.$t("modal.subscribe.free"))),
                  ]),
            ]),
          ]),
          _vm.restoreGroup &&
          (_vm.selectPlan === "Enterprise" || _vm.selectPlan === "Pro")
            ? _c("div", { staticClass: "restoreNotice" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("modal.subscribe.info.restore.text1"))
                ),
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$router.push(
                          "/pointsCalculation?recover=true"
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("modal.subscribe.info.restore.text2")))]
                ),
              ])
            : _vm._e(),
          _c("hr"),
          _c("div", { staticClass: "subscribeInfo" }, [
            _vm.selectPlan === "Basic"
              ? _c("ul", [
                  _c("li", { staticClass: "subscribePlanList" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modal.subscribe.info.basic.text1")) +
                        " "
                    ),
                  ]),
                  _c("li", { staticClass: "subscribePlanList" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modal.subscribe.info.basic.text2")) +
                        " "
                    ),
                  ]),
                  _c("li", { staticClass: "subscribePlanList" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modal.subscribe.info.basic.text3")) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.selectPlan === "Pro"
              ? _c("ul", [
                  _c("li", { staticClass: "subscribePlanList" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modal.subscribe.info.pro.text1")) +
                        " "
                    ),
                  ]),
                  _c("li", { staticClass: "subscribePlanList" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modal.subscribe.info.pro.text2")) +
                        " "
                    ),
                  ]),
                  _c("br"),
                  _c("li", { staticClass: "subscribePlanList important" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modal.subscribe.info.important.text1")) +
                        " "
                    ),
                  ]),
                  _c("li", { staticClass: "subscribePlanList important" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modal.subscribe.info.important.shop")) +
                        " "
                    ),
                  ]),
                  _c("li", { staticClass: "subscribePlanList important" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modal.subscribe.info.important.text3")) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.selectPlan === "Enterprise"
              ? _c("ul", [
                  _c("li", { staticClass: "subscribePlanList" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("modal.subscribe.info.enterprise.text1")
                        ) +
                        " "
                    ),
                  ]),
                  _c("li", { staticClass: "subscribePlanList" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("modal.subscribe.info.enterprise.text2")
                        ) +
                        " "
                    ),
                  ]),
                  _c("br"),
                  _c("li", { staticClass: "subscribePlanList important" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modal.subscribe.info.important.text1")) +
                        " "
                    ),
                  ]),
                  _c("li", { staticClass: "subscribePlanList important" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("modal.subscribe.info.important.business")
                        ) +
                        " "
                    ),
                  ]),
                  _c("li", { staticClass: "subscribePlanList important" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("modal.subscribe.info.important.text3")) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("br"),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pointNotEnough,
                  expression: "pointNotEnough",
                },
              ],
              staticClass: "invalidInput",
            },
            [
              _c("li", [
                _c("h6", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("invalidError.subscribe.pointNotEnough.text1")
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("li", [
                _c("h6", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("invalidError.subscribe.pointNotEnough.text2")
                      ) +
                      " " +
                      _vm._s(_vm.curPoint) +
                      " " +
                      _vm._s(
                        _vm.$t("invalidError.subscribe.pointNotEnough.text3")
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("li", [
                _c("h6", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("invalidError.subscribe.pointNotEnough.text4")
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "h6",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.haveAnyGruop,
                  expression: "haveAnyGruop",
                },
              ],
              staticClass: "invalidInput",
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("invalidError.subscribe.haveGroup")) + " "
              ),
            ]
          ),
          _c(
            "h6",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.inAnyGruop,
                  expression: "inAnyGruop",
                },
              ],
              staticClass: "invalidInput",
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("invalidError.subscribe.inAnyGruop")) + " "
              ),
            ]
          ),
          _c(
            "h6",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShopManagerBefore,
                  expression: "isShopManagerBefore",
                },
              ],
              staticClass: "invalidInput",
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("invalidError.subscribe.shopManagerInCompBefore")
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "h6",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.needToRecover,
                  expression: "needToRecover",
                },
              ],
              staticClass: "invalidInput",
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("invalidError.subscribe.needToRecover.text1")) +
                  " "
              ),
            ]
          ),
          _c(
            "h6",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.needToRecover,
                  expression: "needToRecover",
                },
              ],
              staticClass: "invalidInput",
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("invalidError.subscribe.needToRecover.text2")) +
                  " "
              ),
            ]
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            id: "subscribePlanSuccess",
            centered: "",
            title: _vm.$t("modal.success"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function () {
                return [_c("h5", [_vm._v(_vm._s(_vm.$t("modal.success")))])]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.selectPlan === "Basic" ||
                            _vm.vxAccountShopId ||
                            _vm.vxAccountCompanyId,
                          expression:
                            "\n          selectPlan === 'Basic' || vxAccountShopId || vxAccountCompanyId\n        ",
                        },
                      ],
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "", disabled: _vm.loading },
                      on: {
                        click: function ($event) {
                          close(_vm.$bvModal.hide("subscribePlanSuccess"))
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                  _c(
                    "m-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.selectPlan === "Pro" && !_vm.vxAccountShopId,
                          expression:
                            "selectPlan === 'Pro' && !vxAccountShopId",
                        },
                      ],
                      staticClass: "tableButton cancel",
                      attrs: {
                        disabled: _vm.loading,
                        outlined: _vm.loading,
                        raised: "",
                        dense: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/buildYourShop")
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("button.buildShop")) + " "),
                      _vm.loading
                        ? _c("b-spinner", {
                            staticClass: "buttonLoad",
                            attrs: {
                              small: "",
                              label: "Loading...",
                              variant: "light",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "m-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.selectPlan === "Enterprise" &&
                            !_vm.vxAccountCompanyId,
                          expression:
                            "selectPlan === 'Enterprise' && !vxAccountCompanyId",
                        },
                      ],
                      staticClass: "tableButton cancel",
                      attrs: {
                        disabled: _vm.loading,
                        outlined: _vm.loading,
                        raised: "",
                        dense: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("/buildYourCompany")
                        },
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("button.buildComp")) + " "),
                      _vm.loading
                        ? _c("b-spinner", {
                            staticClass: "buttonLoad",
                            attrs: {
                              small: "",
                              label: "Loading...",
                              variant: "light",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("modal.subscribe.success.text1")) +
                " " +
                _vm._s(_vm.planNameChange) +
                " " +
                _vm._s(_vm.$t("modal.subscribe.success.text2")) +
                " "
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectPlan === "Pro",
                  expression: "selectPlan === 'Pro'",
                },
              ],
            },
            [
              _c("h6", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("modal.subscribe.success.pro")) +
                    " " +
                    _vm._s(_vm.proGroupProple) +
                    " " +
                    _vm._s(_vm.$t("modal.subscribe.success.unit")) +
                    " "
                ),
              ]),
              _c("h6", [
                _vm._v(
                  " " + _vm._s(_vm.$t("modal.subscribe.success.proInfo1")) + " "
                ),
              ]),
              _c("h6", [
                _vm._v(
                  " " + _vm._s(_vm.$t("modal.subscribe.success.proInfo2")) + " "
                ),
              ]),
              _c("h6", [
                _vm._v(
                  " " + _vm._s(_vm.$t("modal.subscribe.success.text5")) + " "
                ),
              ]),
              _c("h6", [
                _vm._v(
                  " " + _vm._s(_vm.$t("modal.subscribe.success.text6")) + " "
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectPlan === "Enterprise",
                  expression: "selectPlan === 'Enterprise'",
                },
              ],
            },
            [
              _c("h6", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("modal.subscribe.success.company")) +
                    " " +
                    _vm._s(_vm.entGroupProple) +
                    " " +
                    _vm._s(_vm.$t("modal.subscribe.success.unit")) +
                    " "
                ),
              ]),
              _c("h6", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("modal.subscribe.success.companyInfo1")) +
                    " "
                ),
              ]),
              _c("h6", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("modal.subscribe.success.companyInfo2")) +
                    " "
                ),
              ]),
              _c("h6", [
                _vm._v(
                  " " + _vm._s(_vm.$t("modal.subscribe.success.text5")) + " "
                ),
              ]),
              _c("h6", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("modal.subscribe.success.shopInComp")) +
                    " "
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "startNewGroupWarning",
            centered: "",
            title: _vm.$t("modal.warning"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.no")) + " ")]
                  ),
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton",
                      attrs: { raised: "", dense: "" },
                      on: { click: _vm.disbandGroupAndCreateNewOne },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(
              _vm._s(_vm.$t("modal.subscribe.info.startNewGroup.warning.text1"))
            ),
          ]),
          _c("h6", [
            _vm._v(
              _vm._s(_vm.$t("modal.subscribe.info.startNewGroup.warning.text2"))
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "newGroupFlow",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
            title: _vm.$t("modal.notice"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { disabled: _vm.loading, raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm.startNewGroupVariable.disband.loading
            ? _c(
                "h6",
                { staticClass: "newGroupFlowTips" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("modal.subscribe.info.startNewGroup.disbanding")
                      ) +
                      " "
                  ),
                  _c("b-spinner", {
                    staticClass: "buttonLoad",
                    attrs: {
                      small: "",
                      label: "Loading...",
                      variant: "primary",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.startNewGroupVariable.disband.success
            ? _c("h6", { staticClass: "newGroupFlowTips" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("modal.subscribe.info.startNewGroup.disbanded")
                    ) +
                    " "
                ),
                _c("i", { staticClass: "fas fa-check-circle success" }),
              ])
            : _vm._e(),
          _vm.startNewGroupVariable.subscribe.flow
            ? _c(
                "h6",
                { staticClass: "newGroupFlowTips" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("modal.subscribe.info.startNewGroup.resubscribe")
                      ) +
                      " "
                  ),
                  _vm.startNewGroupVariable.subscribe.loading
                    ? _c("b-spinner", {
                        staticClass: "buttonLoad",
                        attrs: {
                          small: "",
                          label: "Loading...",
                          variant: "primary",
                        },
                      })
                    : _vm._e(),
                  _vm.startNewGroupVariable.subscribe.error
                    ? _c("i", { staticClass: "fas fa-times-circle error" })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.startNewGroupVariable.subscribe.success
            ? _c("h6", { staticClass: "newGroupFlowTips" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("modal.subscribe.info.startNewGroup.subscribed")
                    ) +
                    " "
                ),
                _c("i", { staticClass: "fas fa-check-circle success" }),
              ])
            : _vm._e(),
        ]
      ),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }