var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "frame" }, [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "tasksTitle" },
        [
          _c("h5", [_vm._v("Tasks List")]),
          _c(
            "m-button",
            {
              staticStyle: { border: "1px solid white" },
              attrs: {
                raised: "",
                disabled: _vm.loading,
                outlined: _vm.loading,
              },
              on: { click: _vm.refreshTasksList },
            },
            [
              _c("i", {
                staticClass: "fas fa-sync-alt",
                class: _vm.loading ? "taskAnimation" : "",
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "tasksContainer textLeft" },
        [
          _vm._m(1),
          _vm._l(_vm.tasks, function (task) {
            return _c("li", { key: task.startTime }, [
              _c("div", [_vm._v(_vm._s(task.time))]),
              _c("div", [
                _vm._v(_vm._s(_vm.$t(`serverTasks.action.${task.action}`))),
              ]),
              _c(
                "div",
                { staticClass: "statusLabel" },
                [
                  task.state === "1"
                    ? _c("i", {
                        staticClass: "fas fa-check green icon",
                        staticStyle: { color: "green" },
                      })
                    : task.state === "-1"
                    ? _c("i", {
                        staticClass: "fas fa-times icon",
                        staticStyle: {
                          color: "red",
                          "font-size": "20px",
                          margin: "0 5px 0 7px",
                        },
                      })
                    : _c("b-spinner", {
                        staticClass: "icon",
                        attrs: {
                          small: "",
                          label: "Loading...",
                          variant: "info",
                        },
                      }),
                  _c("span", { staticClass: "tabeltUp" }, [
                    _vm._v(_vm._s(_vm.$t(`serverTasks.state.${task.state}`))),
                  ]),
                  task.state === "-1"
                    ? _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.bottom.hover",
                            modifiers: { bottom: true, hover: true },
                          },
                        ],
                        staticClass: "fas fa-info-circle info",
                        attrs: { title: _vm.$t("serverTasks.info") },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "formHeader" }, [
      _c("h1", [_vm._v("Server Tasks")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "title" }, [
      _c("div", [_vm._v("Creation time")]),
      _c("div", [_vm._v("Tasks")]),
      _c("div", { staticClass: "statusLabel" }, [_vm._v("Status")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }