var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "formHeader" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("home.leaderboard.title")))]),
        _c("p", [
          _vm._v(" " + _vm._s(_vm.$t("home.leaderboard.subtitle")) + " "),
        ]),
      ]),
      _c(
        "b-overlay",
        {
          staticClass: "rankOverlay",
          attrs: {
            "spinner-variant": "info",
            show: _vm.overlay,
            variant: "light",
            opacity: 0.4,
            blur: "none",
          },
        },
        [
          _c(
            "div",
            { staticClass: "rankBox" },
            [
              _c(
                "b-list-group",
                { staticClass: "rank_list" },
                _vm._l(_vm.rankData, function (list, index) {
                  return _c(
                    "b-list-group-item",
                    {
                      key: list.account,
                      staticClass: "d-flex align-items-center rankitem",
                      class: list.account === _vm.vxAccount ? "myself" : "",
                    },
                    [
                      list.rank === 1
                        ? _c("i", {
                            staticClass: "fas fa-crown gold desktopMode",
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "rank_title desktopMode",
                          class: _vm.rankColor[index],
                        },
                        [_vm._v(" " + _vm._s(_vm.rankName[index]) + " ")]
                      ),
                      _c("b-avatar", {
                        staticClass: "mr-3 desktopMode",
                        attrs: { size: "lg", src: _vm.rankImage[index] },
                      }),
                      _c("b-avatar", {
                        staticClass: "mr-3 deviceMode",
                        attrs: {
                          size: "lg",
                          "badge-variant": _vm.rankDotColor[index],
                          src: _vm.rankImage[index],
                          "badge-offset": "-6px",
                          "badge-left": "",
                          "badge-top": "",
                          badge: _vm.rankName[index],
                        },
                      }),
                      _c("span", { staticClass: "mr-auto rankAccount" }, [
                        _vm._v(_vm._s(list.account)),
                      ]),
                      _c("div", { staticClass: "rankTotal" }, [
                        _c("p", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$t("home.leaderboard.total"))),
                        ]),
                        _c("p", { staticClass: "number" }, [
                          _vm._v(_vm._s(list.total)),
                        ]),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }