var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("formFormat", {
        attrs: {
          page: _vm.page,
          formSelect: _vm.formSelect,
          loading: _vm.loading,
          error: _vm.errorMessage,
          "data-test": "formFormat",
        },
        on: {
          "update:error": function ($event) {
            _vm.errorMessage = $event
          },
          submit: _vm.showEmitValue,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "updateTimezoneSuccess",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
            title: _vm.$t("modal.success"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          close(_vm.$router.push("/home"))
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [_vm._v(_vm._s(_vm.$t("modal.setTimezone.success.text1")))]),
          _c("h6", [_vm._v(_vm._s(_vm.$t("modal.setTimezone.success.text2")))]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }