var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.vxSignInToggle
        ? _c("router-view", { attrs: { id: "nav", name: "nav" } })
        : _c("router-view", { attrs: { id: "nav", name: "language" } }),
      _vm.vxSignInToggle
        ? _c("router-view", { attrs: { id: "sidebar", name: "sidebar" } })
        : _vm._e(),
      _c("div", { staticClass: "gap" }),
      _vm.onlyEnToggle ? _c("showOnlyEn") : _vm._e(),
      _c("router-view", { attrs: { id: "frame" } }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "setCheckToken",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
            title: _vm.$t("modal.error"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function () {
                return [
                  _c("h5", [_vm._v(_vm._s(_vm.$t("modal.error")))]),
                  _c("button", { on: { click: _vm.backToSignInPage } }, [
                    _vm._v("X"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          close(_vm.backToSignInPage())
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(" " + _vm._s(_vm.$t("modal.tokenError")) + " ")])]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "setsomethingWrong",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
            title: _vm.$t("modal.error"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(_vm._s(_vm.$t("modal.somethingWrong")))])]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "unauthorizedErrorToggle",
            centered: "",
            title: _vm.$t("modal.warning"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(_vm._s(_vm.$t("modal.unauthorized")))])]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "accountNotExistToggle",
            centered: "",
            title: _vm.$t("modal.warning"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(_vm._s(_vm.$t("modal.accountNotExist")))])]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "uploadImageToggle",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function () {
                return [
                  _c("h5", [_vm._v(_vm._s(_vm.$t("modal.changeHead")))]),
                  _c("button", { on: { click: _vm.clearUploadImage } }, [
                    _vm._v("X"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          close(_vm.clearUploadImage())
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.close")) + " ")]
                  ),
                  _c(
                    "m-button",
                    {
                      staticClass: "cancel tableButtonUpdate",
                      attrs: {
                        raised: "",
                        dense: "",
                        disabled: !_vm.upLoadButtonToggle,
                      },
                      on: { click: _vm.fnUploadHeadShot },
                    },
                    [
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.loading,
                              expression: "!loading",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.$t("button.save")))]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "loading",
                        },
                        [
                          _c("b-spinner", {
                            attrs: {
                              small: "",
                              label: "Loading...",
                              variant: "light",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("div", { staticClass: "uploadImageBox" }, [
            _vm.changeImage
              ? _c("i", { staticClass: "far fa-user" })
              : _c("img", {
                  staticClass: "headShoot",
                  attrs: { src: _vm.previewImage },
                  on: {
                    error: function ($event) {
                      _vm.changeImage = true
                    },
                  },
                }),
          ]),
          _c("div", { staticClass: "controlBox" }, [
            _c("label", { staticClass: "inputButton" }, [
              _c("input", {
                staticClass: "disabledButton",
                attrs: { type: "file", accept: "image/jpeg" },
                on: { change: _vm.fnChooseHeadShot },
              }),
              _c("p", [_vm._v(_vm._s(_vm.$t("modal.headshot.upload")))]),
            ]),
            _c("p", { class: _vm.upLoadError ? "red" : "" }, [
              _vm._v(" " + _vm._s(_vm.$t("modal.headshot.text")) + " "),
            ]),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "uploadImageSuccess",
            centered: "",
            title: _vm.$t("modal.success"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(_vm._s(_vm.$t("modal.headshot.success")))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }