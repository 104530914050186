var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "frame" },
    [
      _c("formFormat", {
        attrs: {
          page: _vm.page,
          formInput: _vm.formInput,
          error: _vm.errorMessage,
          export: "",
          "data-test": "formFormat",
        },
        on: {
          "update:error": function ($event) {
            _vm.errorMessage = $event
          },
          submit: _vm.showEmitValue,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "createPointSuccess",
            centered: "",
            title: _vm.$t("modal.success"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [_vm._v(_vm._s(_vm.$t("modal.createPoint.success.text1")))]),
          _c("h6", [
            _vm._v(
              _vm._s(_vm.$t("modal.createPoint.success.text2")) +
                " " +
                _vm._s(_vm.pointCode.point)
            ),
          ]),
          _c("h6", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("modal.createPoint.success.text3")) +
                " " +
                _vm._s(_vm.pointCode.setNumber) +
                " "
            ),
          ]),
          _c("br"),
          _c("h6", [_vm._v(_vm._s(_vm.$t("modal.createPoint.success.text4")))]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }