var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "userTitle" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
    _vm.haveData
      ? _c("div", [
          _c(
            "div",
            {
              staticClass: "userNumber",
              class: _vm.isSmallBox ? "smallBox" : "",
            },
            [
              _c("div", { staticClass: "Number" }, [_vm._v(_vm._s(_vm.today))]),
              _c("div", { staticClass: "Percen", class: _vm.percenColor }, [
                _vm._v(" " + _vm._s(_vm.count) + " "),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "userBottom" },
            [
              _c("span", { staticClass: "userDate" }, [
                _vm._v("on " + _vm._s(_vm.data_date)),
              ]),
              _vm._t("text", function () {
                return [_vm._v("text")]
              }),
            ],
            2
          ),
        ])
      : _c("div", [
          _c("div", { staticClass: "userNumber" }, [
            _c(
              "div",
              { staticClass: "Percen" },
              [
                _vm._t("nodata"),
                _c("div", { staticClass: "nodata" }, [
                  _vm._v(" " + _vm._s(_vm.$t("home.nodata")) + " "),
                ]),
              ],
              2
            ),
          ]),
          _c(
            "div",
            { staticClass: "userBottom" },
            [
              _c("span", { staticClass: "userDate" }),
              _vm._t("text", function () {
                return [_vm._v("text")]
              }),
            ],
            2
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }