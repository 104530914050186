var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "logoArea" }, [
    _vm.subdomain === "napa"
      ? _c("img", {
          attrs: {
            id: "logo",
            src: require("@/assets/logo-napa.png"),
            alt: "logo-napa",
          },
        })
      : _c("img", { attrs: { id: "logo", src: require("@/assets/logo.svg") } }),
    _vm.subdomain === "napa"
      ? _c("h1", [_vm._v(_vm._s(_vm.$t("home.title.napa")))])
      : _c("h1", [_vm._v(_vm._s(_vm.$t("home.title.general")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }