var render, staticRenderFns
import script from "./BarChart.vue?vue&type=script&lang=js"
export * from "./BarChart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/TypXw3yGP/0/DHCCorporation/dhc-cloud-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6a09facc')) {
      api.createRecord('6a09facc', component.options)
    } else {
      api.reload('6a09facc', component.options)
    }
    
  }
}
component.options.__file = "src/components/chart/BarChart.vue"
export default component.exports