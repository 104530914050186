var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "div",
        { staticClass: "lastUpdateTime", attrs: { id: "updateTime" } },
        [_vm._v(" Last Updated Time: May 8, 2019 ")]
      ),
      _c("div", { staticClass: "container" }, [
        _c("div", { attrs: { id: "privacyPolicy" } }, [
          _c("h1", { staticClass: "policyTitle" }, [_vm._v("PRIVACY POLICY")]),
          _c("p", [
            _vm._v(
              " We, DHC SPECIALTY CORPORATION, (“we”), offer battery and system testing management service to users of our connected battery and system tester (or charger) devices (“device(s)”), through our cloud service (the “mybatteryexpert.com”) (collectively referred to as the “Service”). "
            ),
          ]),
          _c("p", [
            _vm._v(
              " This Privacy Policy explains how we work with collect, use, share and protect the information in relation to our Service and your choices about the collection and use of your information. "
            ),
          ]),
          _c("h3", { staticClass: "policyTitle" }, [
            _vm._v("INFORMATION WE COLLECT"),
          ]),
          _c("p", [
            _vm._v(
              "We collect information from you including but not limited to:"
            ),
          ]),
          _c("ul", { staticClass: "policySubTitle" }, [
            _vm._v(" Information you provide us directly: "),
            _c("li", [
              _vm._v(
                " basic user information, including your name, user ID, email address, company name, dealer code. "
              ),
            ]),
            _c("li", [
              _vm._v(
                " device and application information, including serial numbers, software version, hardware model, OS information. "
              ),
            ]),
            _c("li", [
              _vm._v(
                " battery and system charge and test results, including battery brand, battery type, battery rating, test time, any other information included in a test report. "
              ),
            ]),
          ]),
          _c("ul", { staticClass: "policySubTitle" }, [
            _vm._v(" Automatic collected Information: "),
            _c("li", [
              _vm._v(
                " information for customized setting, including cookies, sessions, token, local storage, language. "
              ),
            ]),
            _c("li", [
              _vm._v(
                " information for troubleshooting, analyzing and optimizing, including system logs, access logs, error logs, application logs, IP address, browser type, web requests. "
              ),
            ]),
          ]),
          _c("h3", { staticClass: "policyTitle" }, [
            _vm._v("HOW WE USE PERSONAL INFORMATION"),
          ]),
          _c("p", [
            _vm._v(
              " We use your personal information to operate, provide, and improve our products and services and to refine our products and services to your particular needs. "
            ),
          ]),
          _c("ul", { staticClass: "policySubTitle" }, [
            _vm._v(" We may use the information we collect to: "),
            _c("li", [
              _vm._v(
                " for any other purpose in connection with our products and Service. "
              ),
            ]),
            _c("li", [
              _vm._v(
                " communications with you. For example, we may send Service-related emails (e.g., account verification, technical and security notices). Note that you may not opt out of Service-related e-mails. "
              ),
            ]),
            _c("li", [
              _vm._v("personalize your online experience with our Service."),
            ]),
            _c("li", [
              _vm._v(
                " Measure, Support and Improve our Service. We use your personal information to measure use of, analyze the performance of, fix errors in, provide support for, improve, and develop our Service. "
              ),
            ]),
            _c("li", [
              _vm._v(
                " use the information provided to track and analyze trends in battery performance. "
              ),
            ]),
          ]),
          _c("h3", { staticClass: "policyTitle" }, [
            _vm._v("SHARING OF PERSONAL INFORMATION"),
          ]),
          _c("p", [
            _vm._v(
              " We will not rent or sell your information to third parties without your consent, except as noted in this Policy. Parties with whom we may share your information: "
            ),
          ]),
          _c("h4", { staticClass: "policySubTitle" }, [
            _vm._v("Third-Party Service Providers:"),
          ]),
          _c("p", [
            _vm._v(
              " We employ other companies and individuals to perform functions on our behalf. Examples include: Examples include: sending communications, processing payments, assessing credit and compliance risks, analyzing data, providing marketing and sales assistance (including advertising and event management), conducting customer relationship management, providing training, security check, troubleshooting, and architecture optimization. These third party service providers have access to personal information needed to perform their functions but may not use it for other purposes. Further, they must process that information in accordance with this Privacy Notice and as permitted by applicable data protection law. "
            ),
          ]),
          _c("h4", { staticClass: "policySubTitle" }, [
            _vm._v(" As Required by Law and Other Extraordinary Disclosures: "),
          ]),
          _c("ul", [
            _vm._v(
              " we may be required to disclose your Personal Information, including: "
            ),
            _c("li", [
              _vm._v(
                " it believes it is reasonably necessary to comply with legal process (such as a court order, subpoena, search warrant, etc.) or other legal requirements of any governmental authority. "
              ),
            ]),
            _c("li", [
              _vm._v(
                " it would potentially mitigate our liability in an actual or potential lawsuit or investigation. "
              ),
            ]),
            _c("li", [
              _vm._v(
                "it is otherwise necessary to protect our rights or property."
              ),
            ]),
            _c("li", [
              _vm._v(
                " it is necessary to protect the legal rights or property of others. "
              ),
            ]),
          ]),
          _c("h3", { staticClass: "policyTitle" }, [
            _vm._v(
              " LOCATION OF STORAGE AND PROCESSING OF PERSONAL INFORMATION "
            ),
          ]),
          _c("p", [
            _vm._v(
              " Your personal information is stored and processed in Northern Virginia unless stated otherwise in the rest of this document. By using the Service, you consent to the transfer of information to Northern Virginia and the use and disclosure of information as described in this Privacy Policy. "
            ),
          ]),
          _c("h3", { staticClass: "policyTitle" }, [_vm._v("SECURITY")]),
          _c("p", [
            _vm._v(
              " We implement appropriate physical, electronic and organizational measures to protect your data. These measures are in accordance with recognized industry standards during and after transmission. Data transmissions over the Internet cannot be guaranteed to be 100% secure. While we will do our best to protect your personal information, we cannot guarantee that your information will be transmitted securely to our Service. If we learn of a security system's breach, we will investigate the situation and take all steps required by applicable law. This includes notifying the authority to protect the data, where applicable, and, where appropriate, notifying individuals whose personal data may have been compromised. we may take additional steps in accordance with any applicable laws and regulations. "
            ),
          ]),
          _c("h3", { staticClass: "policyTitle" }, [
            _vm._v("YOUR CHOICES ABOUT YOUR INFORMATION"),
          ]),
          _c("p", [
            _vm._v(
              " You can view, update and delete the information about yourself by logging in our Service or contact us for assistance. When you update or delete any information, we usually keep a copy of the prior version for a commercially reasonable time for backup, archival and audit purposes. "
            ),
          ]),
          _c("h3", { staticClass: "policyTitle" }, [
            _vm._v("CHANGES TO THIS PRIVACY POLICY"),
          ]),
          _c("p", [
            _vm._v(
              " In the future, we may change our Privacy Policy and we will post the new Privacy Policy on our Service. Your continued use of our Service after any modification to this Privacy Policy will constitute your acceptance of such modification. We encourage you to review our policy periodically to remain current on our policies. "
            ),
          ]),
          _c("h3", { staticClass: "policyTitle" }, [_vm._v("CONTACT")]),
          _c("p", [
            _vm._v(
              " If you have any questions, comments, or concerns about this Privacy Policy, please contact us at "
            ),
            _c("a", { attrs: { href: "mailto:service@dhc.com.tw" } }, [
              _vm._v("service@dhc.com.tw."),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }