var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "frame" },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "formHeader" }, [
          _c("h1", [_vm._v(_vm._s(_vm.$t("download.title")))]),
          _c("p", [_vm._v(_vm._s(_vm.$t("download.subtitle")))]),
          _c(
            "div",
            { attrs: { id: "download" } },
            [
              _c(
                "m-button",
                {
                  staticClass: "tableButton",
                  attrs: { raised: "", dense: "" },
                  on: { click: _vm.fnExport },
                },
                [_vm._v(" " + _vm._s(_vm.$t("button.download")) + " ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "m-button",
        {
          staticClass: "gobackButton",
          attrs: { outlined: "", raised: "" },
          on: {
            click: function ($event) {
              return _vm.$router.push(_vm.routerPushURL)
            },
          },
        },
        [_vm._v("back to MybatteryExpert.com")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }