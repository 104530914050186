var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("nav", { attrs: { id: "languageBar" } }, [
      _c(
        "div",
        { attrs: { id: "language" }, on: { click: _vm.fnLanguageToggle } },
        [_vm._v(" " + _vm._s(_vm.$t("nav.Language")) + " ")]
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.languageBoxToggle,
              expression: "languageBoxToggle",
            },
          ],
          attrs: { id: "languageBox" },
        },
        [
          _c("li", { attrs: { id: "en" }, on: { click: _vm.en } }, [
            _vm._v("English"),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }