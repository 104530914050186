var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "frame" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "formHeader" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t(_vm.page + ".title")))]),
        _c("p", [_vm._v(_vm._s(_vm.$t(_vm.page + ".subtitle")))]),
      ]),
      this.export
        ? _c(
            "form",
            {
              attrs: { id: "formFormat", action: _vm.api, method: "POST" },
              on: { submit: _vm.emitValue },
            },
            [
              _c(
                "div",
                { staticClass: "formBody" },
                [
                  _vm._l(_vm.formInput, function (item, index) {
                    return _c("div", { key: item.title }, [
                      _c("label", { staticClass: "formInputBox" }, [
                        _c("p", [_vm._v(_vm._s(_vm.$t(item.title)))]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.inputValue[index],
                              expression: "inputValue[index]",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "formInput",
                          attrs: {
                            autocomplete: _vm.autocomplete,
                            type: item.type,
                            placeholder: _vm.$t(item.placeholder),
                            name: item.id,
                            required: "",
                          },
                          domProps: { value: _vm.inputValue[index] },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.inputValue,
                                  index,
                                  _vm._n($event.target.value)
                                )
                              },
                              function ($event) {
                                return _vm.checkInput(item.check, index)
                              },
                            ],
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.error.RegPoint && item.check === "point",
                              expression:
                                "error.RegPoint && item.check === 'point'",
                            },
                          ],
                          staticClass: "invalidInput",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("invalidError.createPoint.onlyNumber")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.error.RegSetNumber &&
                                item.check === "setNumber",
                              expression:
                                "error.RegSetNumber && item.check === 'setNumber'",
                            },
                          ],
                          staticClass: "invalidInput",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("invalidError.createPoint.onlyNumber")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.error.setNumberError &&
                                item.check === "setNumber",
                              expression:
                                "error.setNumberError && item.check === 'setNumber'",
                            },
                          ],
                          staticClass: "invalidInput",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("invalidError.createPoint.lessThan")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    staticClass: "formInput",
                    attrs: { name: "token", type: "text" },
                    domProps: { value: _vm.formToken },
                  }),
                ],
                2
              ),
              _c("hr", { staticClass: "formLine" }),
              _c(
                "m-button",
                {
                  staticClass: "tableButton",
                  attrs: {
                    raised: "",
                    disabled: _vm.buttonDisabled,
                    outlined: _vm.buttonDisabled,
                    dense: !_vm.buttonDisabled,
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("button." + _vm.buttonName)) + " "
                  ),
                  _vm.loading
                    ? _c("b-spinner", {
                        staticClass: "buttonLoad",
                        attrs: {
                          small: "",
                          label: "Loading...",
                          variant: "light",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "form",
            {
              attrs: { id: "formFormat" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.emitValue.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "formBody" },
                [
                  _vm._l(_vm.formInput, function (item, index) {
                    return _c("div", { key: item.title }, [
                      _c("label", { staticClass: "formInputBox" }, [
                        _c("p", [_vm._v(_vm._s(_vm.$t(item.title)))]),
                        _c("div", { staticClass: "input-group" }, [
                          item.type === "checkbox"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.inputValue[index],
                                    expression: "inputValue[index]",
                                  },
                                ],
                                staticClass: "formInput",
                                attrs: {
                                  autocomplete: _vm.autocomplete,
                                  placeholder: _vm.$t(item.placeholder),
                                  required: "",
                                  name: item.id,
                                  type: "checkbox",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.inputValue[index])
                                    ? _vm._i(_vm.inputValue[index], null) > -1
                                    : _vm.inputValue[index],
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkInput(item.check, index)
                                  },
                                  change: function ($event) {
                                    var $$a = _vm.inputValue[index],
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.inputValue,
                                            index,
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.inputValue,
                                            index,
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.inputValue, index, $$c)
                                    }
                                  },
                                },
                              })
                            : item.type === "radio"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.inputValue[index],
                                    expression: "inputValue[index]",
                                  },
                                ],
                                staticClass: "formInput",
                                attrs: {
                                  autocomplete: _vm.autocomplete,
                                  placeholder: _vm.$t(item.placeholder),
                                  required: "",
                                  name: item.id,
                                  type: "radio",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.inputValue[index], null),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkInput(item.check, index)
                                  },
                                  change: function ($event) {
                                    return _vm.$set(_vm.inputValue, index, null)
                                  },
                                },
                              })
                            : _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.inputValue[index],
                                    expression: "inputValue[index]",
                                  },
                                ],
                                staticClass: "formInput",
                                attrs: {
                                  autocomplete: _vm.autocomplete,
                                  placeholder: _vm.$t(item.placeholder),
                                  required: "",
                                  name: item.id,
                                  type: item.type,
                                },
                                domProps: { value: _vm.inputValue[index] },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.inputValue,
                                        index,
                                        $event.target.value
                                      )
                                    },
                                    function ($event) {
                                      return _vm.checkInput(item.check, index)
                                    },
                                  ],
                                },
                              }),
                          item.id === "password" ||
                          item.id === "confirmPassword"
                            ? _c(
                                "div",
                                {
                                  attrs: { id: "passwordToggle" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.fnEyeSwitch(index)
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _c("i", {
                                      staticClass: "fas fa-eye",
                                      class: _vm.eyeSwitch[index],
                                      attrs: {
                                        id: "eye",
                                        "aria-hidden": "true",
                                      },
                                    }),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.page === "createShop" ||
                                _vm.page === "createCompany",
                              expression:
                                "page === 'createShop' || page === 'createCompany'",
                            },
                          ],
                          staticClass: "subscribeInput groupIdFormat",
                          class: _vm.error.RegGroupID ? "invalidInput" : "",
                        },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("invalidError.createGruop.title1"))
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("invalidError.createGruop.title2"))
                            ),
                          ]),
                          _c("br"),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("invalidError.createGruop.title3"))
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("invalidError.createGruop.list1"))
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("invalidError.createGruop.list2"))
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("invalidError.createGruop.list3"))
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("invalidError.createGruop.list4"))
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("invalidError.createGruop.list5"))
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.error.existGroupID,
                              expression: "error.existGroupID",
                            },
                          ],
                          staticClass: "invalidInput",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("invalidError.createGruop.existID")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.error.RegPassword &&
                                item.check === "password",
                              expression:
                                "error.RegPassword && item.check === 'password'",
                            },
                          ],
                          staticClass: "invalidInput",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("invalidError.changePassword.title")
                              ) +
                              " "
                          ),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("invalidError.changePassword.list1")
                              )
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("invalidError.changePassword.list2")
                              )
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("invalidError.changePassword.list3")
                              )
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("invalidError.changePassword.list4")
                              )
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("invalidError.changePassword.list5")
                              )
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("invalidError.changePassword.list6")
                              )
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("invalidError.changePassword.list7")
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.error.confirmPassword &&
                                item.check === "confirm",
                              expression:
                                "error.confirmPassword && item.check === 'confirm'",
                            },
                          ],
                          staticClass: "invalidInput",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "invalidError.changePassword.confirmPasswordError"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.error.invalidCode,
                              expression: "error.invalidCode",
                            },
                          ],
                          staticClass: "invalidInput",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("invalidError.stroedPoint.invalid")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.error.codeAlreadyUsed,
                              expression: "error.codeAlreadyUsed",
                            },
                          ],
                          staticClass: "invalidInput",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("invalidError.stroedPoint.used")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.error.mailErrorToggle &&
                                item.check === "email",
                              expression:
                                "error.mailErrorToggle && item.check === 'email'",
                            },
                          ],
                          staticClass: "invalidInput",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("invalidError.registerUser.mailError")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  }),
                  _vm._l(_vm.formSelect, function (item, index) {
                    return _c("div", { key: item.title }, [
                      _c("label", { staticClass: "formInputBox" }, [
                        _c("p", [_vm._v(_vm._s(_vm.$t(item.title)))]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectCountry[index],
                                expression: "selectCountry[index]",
                              },
                            ],
                            staticClass: "timezoneBox",
                            attrs: { required: "" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.selectCountry,
                                    index,
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.showCityList(
                                    _vm.selectCountry[index]
                                  )
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "option",
                              {
                                attrs: {
                                  value: "",
                                  selected: "",
                                  disabled: "",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t(`formFormat.selectText1`)) +
                                    " "
                                ),
                              ]
                            ),
                            _vm._l(_vm.timezoneCountry, function (country) {
                              return _c(
                                "option",
                                { key: country, domProps: { value: country } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(`formFormat.country.${country}`)
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _vm.formSelect !== null && _vm.cityListToggle
                        ? _c("label", { staticClass: "formInputBox" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectValue[index],
                                    expression: "selectValue[index]",
                                  },
                                ],
                                staticClass: "timezoneBox",
                                attrs: { required: "" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.selectValue,
                                      index,
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value: "",
                                      selected: "",
                                      disabled: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(`formFormat.selectText2`)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._l(_vm.cityList, function (city) {
                                  return _c(
                                    "option",
                                    { key: city, domProps: { value: city } },
                                    [_vm._v(" " + _vm._s(city) + " ")]
                                  )
                                }),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                    ])
                  }),
                ],
                2
              ),
              _c("hr", { staticClass: "formLine" }),
              _vm.page === "register" && _vm.subdomain !== "napa"
                ? _c("div", { staticClass: "subscribeInput" }, [
                    _vm._v(" " + _vm._s(_vm.$t("register.notice")) + " "),
                  ])
                : _vm._e(),
              _c(
                "m-button",
                {
                  staticClass: "tableButton",
                  attrs: {
                    raised: "",
                    disabled: _vm.buttonDisabled,
                    outlined: _vm.buttonDisabled,
                    dense: !_vm.buttonDisabled,
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("button." + _vm.buttonName)) + " "
                  ),
                  _vm.loading
                    ? _c("b-spinner", {
                        staticClass: "buttonLoad",
                        attrs: {
                          small: "",
                          label: "Loading...",
                          variant: "light",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.page === "register"
                ? _c(
                    "p",
                    { staticClass: "formFooter" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          model: {
                            value: _vm.checkPolicy,
                            callback: function ($$v) {
                              _vm.checkPolicy = $$v
                            },
                            expression: "checkPolicy",
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("register.check1")) + " "),
                          _c(
                            "router-link",
                            { attrs: { to: "/privacyPolicy", tag: "span" } },
                            [
                              _c("a", { attrs: { target: "_blank" } }, [
                                _vm._v(_vm._s(_vm.$t("nav.PrivacyPolicy"))),
                              ]),
                            ]
                          ),
                          _vm._v(". "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }