var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container signinBox" },
    [
      _vm.subdomain === "napa"
        ? _c("img", {
            attrs: {
              id: "logo",
              src: require("@/assets/logo-napa.png"),
              alt: "logo-napa",
            },
          })
        : _c(
            "svg",
            {
              attrs: {
                id: "logo",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                x: "0px",
                y: "0px",
                viewBox: "0 0 511.936 511.936",
                "enable-background": "new 0 0 511.936 511.936",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M0.082,147.554c3.669-8.934,7.734-17.528,15.403-23.985\n      c8.551-7.199,18.167-11.924,29.348-11.934c140.789-0.116,281.577-0.227,422.365,0.081c21.907,0.048,39.341,16.239,44.723,37.063\n      c0.404,1.564,0.095,3.318,0.095,4.984c0.002,91.471,0.044,182.942-0.044,274.413c-0.013,13.594-6.913,23.895-16.996,32.34\n      c-10.2,8.545-22.207,11.057-35.211,11.049c-137.124-0.082-274.246,0.029-411.37-0.137c-20.891-0.025-36.041-10.184-45.279-28.949\n      c-1.249-2.537-2.035-5.303-3.034-7.961C0.082,338.863,0.082,243.209,0.082,147.554z M255.881,133.556\n      c-68.486,0-136.972-0.038-205.457,0.097c-3.987,0.008-8.172,0.866-11.919,2.258c-12.954,4.813-17.325,15.673-17.309,28.148\n      c0.114,87.414,0.524,174.828,0.938,262.242c0.031,6.641,3.938,11.74,8.54,16.016c6.381,5.928,14.619,7.131,22.837,7.137\n      c132.806,0.09,265.612,0.074,398.417,0.02c5.429-0.002,10.907-0.281,16.273-1.057c6.633-0.957,11.826-4.328,16.363-9.602\n      c5.705-6.629,6.298-14.459,6.318-22.146c0.22-83.814,0.158-167.632,0.074-251.447c-0.004-3.895-0.521-7.898-1.517-11.662\n      c-3.443-13.012-14.229-19.905-27.104-19.932C393.519,133.477,324.699,133.556,255.881,133.556z",
                },
              }),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M185.059,102.315c-7.413,0-14.343,0-21.997,0c0-8.41-0.218-16.836,0.048-25.248\n      c0.454-14.325-1.243-15.887-15.64-15.573c-14.649,0.318-29.311,0.067-43.967,0.071c-8.149,0.002-10.431,2.337-10.433,10.665\n      c-0.002,9.947,0,19.894,0,30.082c-7.407,0-14.337,0-21.998,0c0-10.234-0.179-20.488,0.089-30.73\n      c0.105-4.023,0.967-8.077,1.909-12.016c2.445-10.236,14.997-18.867,24.304-19.013c18.418-0.291,36.841-1.088,55.252-0.848\n      c14.97,0.196,26.797,7.229,30.577,20.493c0.972,3.413,1.694,7.013,1.772,10.544C185.207,81.184,185.059,91.632,185.059,102.315z",
                },
              }),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M349.038,102.444c-7.63,0-14.441,0-21.997,0c0-10.345-0.179-20.598,0.089-30.839\n      c0.105-4.022,0.972-8.075,1.903-12.016c2.431-10.262,15.021-18.888,24.285-19.036c18.419-0.294,36.844-1.129,55.251-0.84\n      c15.63,0.246,26.723,7.391,30.883,20.861c0.999,3.234,1.443,6.747,1.505,10.143c0.189,10.445,0.069,20.895,0.069,31.589\n      c-7.396,0-14.326,0-21.997,0c0-2.884,0-5.806,0-8.728c0-7.994,0.069-15.989-0.027-23.982c-0.058-4.751-2.97-7.959-7.544-7.984\n      c-18.32-0.104-36.641-0.11-54.961,0.005c-4.552,0.029-7.391,3.279-7.435,8.092c-0.082,9.159-0.024,18.32-0.024,27.48\n      C349.038,98.81,349.038,100.433,349.038,102.444z",
                },
              }),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M327.284,307.713c0-11.164,0-21.426,0-32.057c13.5,0,26.765,0,40.572,0\n      c0-13.738,0-27.125,0-40.809c10.924,0,21.305,0,32.056,0c0,13.439,0,26.703,0,40.51c13.741,0,27.126,0,40.81,0\n      c0,10.926,0,21.307,0,32.057c-13.438,0-26.704,0-40.511,0c0,13.74,0,27.127,0,40.811c-10.924,0-21.305,0-32.057,0\n      c0-13.439,0-26.705,0-40.512C354.416,307.713,341.028,307.713,327.284,307.713z",
                },
              }),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M184.838,275.893c0,10.59,0,20.85,0,31.375c-37.793,0-75.367,0-113.354,0\n      c0-10.254,0-20.635,0-31.375C109.028,275.893,146.72,275.893,184.838,275.893z",
                },
              }),
            ]
          ),
      _vm.subdomain === "napa"
        ? _c("p", { attrs: { id: "napa-logo" } })
        : _c("p", { attrs: { id: "freeLogo" } }, [
            _vm._v(" " + _vm._s(_vm.$t("nav.free")) + " "),
          ]),
      _c("transition", { attrs: { name: "hide" } }, [
        !_vm.forgotPasswordPageToggle
          ? _c(
              "form",
              {
                staticClass: "form-signin",
                attrs: { "data-test": "form-signIn" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.fnAPISignIn.apply(null, arguments)
                  },
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account,
                      expression: "account",
                    },
                  ],
                  attrs: {
                    id: "inputEmail",
                    type: "email",
                    placeholder: _vm.$t("signIn.text1Placeholder"),
                    "data-test": "input-account-TYPE",
                    required: "",
                    autofocus: "",
                  },
                  domProps: { value: _vm.account },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.account = $event.target.value
                    },
                  },
                }),
                _c("div", { staticClass: "input-group" }, [
                  (_vm.eyeToggle ? "password" : "text") === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password",
                          },
                        ],
                        attrs: {
                          id: "inputPassword",
                          placeholder: _vm.$t("signIn.text2Placeholder"),
                          "data-test": "input-password-TYPE",
                          required: "",
                          type: "checkbox",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.password)
                            ? _vm._i(_vm.password, null) > -1
                            : _vm.password,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.password,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.password = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.password = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.password = $$c
                            }
                          },
                        },
                      })
                    : (_vm.eyeToggle ? "password" : "text") === "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password",
                          },
                        ],
                        attrs: {
                          id: "inputPassword",
                          placeholder: _vm.$t("signIn.text2Placeholder"),
                          "data-test": "input-password-TYPE",
                          required: "",
                          type: "radio",
                        },
                        domProps: { checked: _vm._q(_vm.password, null) },
                        on: {
                          change: function ($event) {
                            _vm.password = null
                          },
                        },
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password",
                          },
                        ],
                        attrs: {
                          id: "inputPassword",
                          placeholder: _vm.$t("signIn.text2Placeholder"),
                          "data-test": "input-password-TYPE",
                          required: "",
                          type: _vm.eyeToggle ? "password" : "text",
                        },
                        domProps: { value: _vm.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.password = $event.target.value
                          },
                        },
                      }),
                  _c(
                    "div",
                    {
                      attrs: { id: "passwordToggle" },
                      on: {
                        click: function ($event) {
                          _vm.eyeToggle = !_vm.eyeToggle
                        },
                      },
                    },
                    [
                      _c("span", [
                        _c("i", {
                          staticClass: "fas fa-eye",
                          class: _vm.eyeToggle ? "fa-eye-slash" : "",
                          attrs: { id: "eye", "aria-hidden": "true" },
                        }),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "m-button",
                  {
                    attrs: {
                      id: "signInBtn",
                      disabled: _vm.loading,
                      raised: "",
                      outlined: _vm.loading,
                      "data-test": "button-signin-CLICK",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("signIn.signIn")))]
                ),
                _c(
                  "div",
                  { staticClass: "other" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { id: "forgotPassword" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.clickForgotPassword.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("signIn.forgotPassword.title")))]
                    ),
                    _c("router-link", { attrs: { to: "/register" } }, [
                      _vm._v(_vm._s(_vm.$t("signIn.register"))),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "hide" } }, [
        _vm.forgotPasswordPageToggle
          ? _c(
              "form",
              {
                staticClass: "form-forgotPassword",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.fnResetPassword.apply(null, arguments)
                  },
                },
              },
              [
                _c("h3", { attrs: { id: "forgotPasswordTitle" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("signIn.forgotPassword.title")) + " "
                  ),
                ]),
                _c("h6", { staticClass: "forgotPasswordP" }, [
                  _vm._v(_vm._s(_vm.$t("signIn.forgotPassword.text1"))),
                ]),
                _c("h6", { staticClass: "forgotPasswordP" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("signIn.forgotPassword.text2")) + " "
                  ),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account,
                      expression: "account",
                    },
                  ],
                  attrs: {
                    id: "inputEmail",
                    type: "email",
                    placeholder: _vm.$t("signIn.text1Placeholder"),
                    required: "",
                    autofocus: "",
                  },
                  domProps: { value: _vm.account },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.account = $event.target.value
                    },
                  },
                }),
                _c(
                  "m-button",
                  {
                    attrs: {
                      disabled: _vm.loading,
                      outlined: _vm.loading,
                      raised: "",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("button.submit")))]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.MailPwError
        ? _c(
            "div",
            {
              staticClass: "invalidInput",
              attrs: { "data-test": "invalid-mailpwError" },
            },
            [_vm._v(" " + _vm._s(_vm.pwErrorMessage) + " ")]
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "forgotPasswordError",
            centered: "",
            title: _vm.$t("modal.error"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.signIn.forgotPassword.error.text1"))),
          ]),
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.signIn.forgotPassword.error.text2"))),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "forgotPasswordSuccess",
            centered: "",
            title: _vm.$t("modal.success"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          close((_vm.forgotPasswordPageToggle = false))
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.signIn.forgotPassword.success.text1"))),
          ]),
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.signIn.forgotPassword.success.text2"))),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "resetPassword",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
            title: _vm.$t("modal.notice"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          close(_vm.$router.push("/changePassword"))
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.signIn.resetPassword.text1"))),
          ]),
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.signIn.resetPassword.text2"))),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "verified",
            centered: "",
            title: _vm.$t("modal.notice"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          ok(_vm.fnResentMail())
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.resentMail")) + " ")]
                  ),
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [_vm._v(_vm._s(_vm.$t("modal.signIn.verified.text1")))]),
          _c("h6", [_vm._v(_vm._s(_vm.$t("modal.signIn.verified.text2")))]),
          _c("h6", [_vm._v(_vm._s(_vm.$t("modal.signIn.verified.text3")))]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "afterSendEmail",
            centered: "",
            title: _vm.$t("modal.notice"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [_vm._v(_vm._s(_vm.$t("modal.signIn.sendEmail.text1")))]),
          _c("h6", [_vm._v(_vm._s(_vm.account))]),
          _c("h6", [_vm._v(_vm._s(_vm.$t("modal.signIn.sendEmail.text2")))]),
          _c("h6", [
            _vm._v(" " + _vm._s(_vm.$t("modal.signIn.sendEmail.text3")) + " "),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "pwExpired",
            centered: "",
            title: _vm.$t("modal.warning"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          ok(_vm.$router.push("/changePassword"))
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(_vm._s(_vm.$t("modal.signIn.pwExpired")))])]
      ),
      !_vm.MailPwError
        ? _c(
            "div",
            { staticClass: "loader", class: _vm.loading ? "active" : "" },
            [
              _c("b-spinner", {
                attrs: { label: "Loading...", variant: "info" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.forgotPasswordPageToggle
        ? _c(
            "m-button",
            {
              staticClass: "tableButton",
              attrs: { raised: "", dense: "" },
              on: { click: _vm.clickForgotPassword },
            },
            [_vm._v(" " + _vm._s(_vm.$t("button.back")) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }