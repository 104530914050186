var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "dashboardTop" }, [
      _c("div", { staticClass: "dashboardTitle" }, [
        _c("h1", [
          _vm._v(
            _vm._s(_vm.vxShopID) + " " + _vm._s(_vm.$t("chart.list.shop"))
          ),
        ]),
        _c("h1", [
          _vm._v(" " + _vm._s(_vm.dashboardTimezone)),
          _vm.dashboardTimezone !== "" &&
          _vm.vxTimezone !== _vm.DATAnewestUpdateTime.timezone
            ? _c("span", { staticStyle: { "font-size": "16px" } }, [
                _vm._v("(" + _vm._s(_vm.$t("formFormat.willUpdate")) + ")"),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._m(0),
    ]),
    _c(
      "div",
      { staticClass: "frameContainer" },
      [
        _c(
          "b-row",
          { staticClass: "dashboardButtons" },
          [
            _c(
              "b-col",
              {
                staticClass: "button-group fixFlex",
                attrs: { lg: "6", md: "12" },
              },
              _vm._l(_vm.buttonArray, function (button, index) {
                return _c(
                  "m-button",
                  {
                    key: button.name,
                    staticClass: "tableButton",
                    attrs: {
                      disabled: button.disabled || _vm.loading,
                      raised: button.action,
                      outlined: !button.action,
                      dense: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.fnDeviceChange(button.name, index)
                      },
                    },
                  },
                  [
                    button.loading
                      ? _c(
                          "div",
                          { staticClass: "loading" },
                          [
                            _c("b-spinner", {
                              attrs: {
                                small: "",
                                label: "Loading...",
                                variant: "info",
                              },
                            }),
                          ],
                          1
                        )
                      : _c("p", [
                          _vm._v(_vm._s(_vm.getButtonModelName(button.name))),
                        ]),
                  ]
                )
              }),
              1
            ),
            _c(
              "b-col",
              { staticClass: "fixFlex", attrs: { lg: "6", md: "12" } },
              [
                _vm.DataTimeToggle
                  ? _c("div", { staticClass: "lastUpdateTime" }, [
                      _vm._v(" " + _vm._s(_vm.newestUpdateTime) + " "),
                    ])
                  : _c("div", { staticClass: "lastUpdateTime" }),
              ]
            ),
          ],
          1
        ),
        _c(
          "b-row",
          { staticClass: "chartBox" },
          [
            _c(
              "b-col",
              {
                staticClass: "p0",
                attrs: { sm: "12", md: "6", lg: "6", xl: "4" },
              },
              [
                _c(
                  "UserBox",
                  {
                    staticClass: "chartUser chartArea",
                    attrs: {
                      data_userChart: _vm.DATAactiveUser.newUser,
                      data_date: _vm.DATAactiveUser.date,
                      title: _vm.$t("chart.title.new"),
                      haveData: _vm.NoDataUserToggle,
                    },
                  },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.bottom.hover",
                          modifiers: { bottom: true, hover: true },
                        },
                      ],
                      staticClass: "fas fa-info-circle info",
                      attrs: { slot: "text", title: _vm.$t("chart.info.new") },
                      slot: "text",
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "b-col",
              {
                staticClass: "p0",
                attrs: { sm: "12", md: "6", lg: "6", xl: "4" },
              },
              [
                _c(
                  "UserBox",
                  {
                    staticClass: "chartUser chartArea",
                    attrs: {
                      data_userChart: _vm.DATAactiveUser.activeUser,
                      data_date: _vm.DATAactiveUser.date,
                      title: _vm.$t("chart.title.active"),
                      haveData: _vm.NoDataUserToggle,
                    },
                  },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.bottom.hover",
                          modifiers: { bottom: true, hover: true },
                        },
                      ],
                      staticClass: "fas fa-info-circle info",
                      attrs: {
                        slot: "text",
                        title: _vm.$t("chart.info.active"),
                      },
                      slot: "text",
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "b-col",
              { staticClass: "p0", attrs: { lg: "12", xl: "4" } },
              [
                _c(
                  "UserBox",
                  {
                    staticClass: "chartUser chartArea",
                    attrs: {
                      data_userChart: _vm.DATAactiveUser.totalUser,
                      data_date: _vm.DATAactiveUser.date,
                      title: _vm.$t("chart.title.totalUesr"),
                      haveData: _vm.NoDataUserToggle,
                    },
                  },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.bottom.hover",
                          modifiers: { bottom: true, hover: true },
                        },
                      ],
                      staticClass: "fas fa-info-circle info",
                      attrs: {
                        slot: "text",
                        title: _vm.$t("chart.info.total"),
                      },
                      slot: "text",
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-row",
          { staticClass: "chartBox" },
          [
            _c("b-col", { staticClass: "p0", attrs: { xl: "4", lg: "6" } }, [
              _vm.NoDataFwToggle
                ? _c(
                    "div",
                    { staticClass: "chartArea chartBarFirmware" },
                    [
                      _c("HorizontalBarChart", {
                        staticClass: "chart",
                        attrs: {
                          chartData: _vm.DATAversionByDay,
                          chartTitle: [
                            _vm.$t("chart.title.firmwareVersion"),
                            _vm.APIversionByDay.date
                              ? _vm.APIversionByDay.date
                              : "",
                          ],
                          chart: "firmware",
                        },
                      }),
                      _c(
                        "ul",
                        { staticClass: "labelsBox labelFirmware" },
                        _vm._l(_vm.firmwareLabel, function (label, index) {
                          return _c(
                            "li",
                            { key: index, staticClass: "label" },
                            [
                              _c("span", {
                                staticClass: "labelColor",
                                style: { background: label.color },
                              }),
                              _c("div", { staticClass: "labelText" }, [
                                _c("p", [_vm._v(_vm._s(label.name))]),
                                _c("p", [
                                  _vm._v(
                                    _vm._s(label.num) +
                                      " (" +
                                      _vm._s(label.percen) +
                                      "%)"
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "chartBarFirmware chartArea noDataChart" },
                    [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$t("chart.title.firmwareVersion"))),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("home.nodata")))]),
                    ]
                  ),
            ]),
            _c("b-col", { staticClass: "p0", attrs: { xl: "4", lg: "6" } }, [
              _vm.NoDataPerDayToggle
                ? _c(
                    "div",
                    { staticClass: "chartArea chartBarHourlyByDay" },
                    [
                      _c("BarChart", {
                        staticClass: "chart",
                        attrs: {
                          chartData: _vm.DATAusageByDay,
                          chartTitle:
                            _vm.$t("chart.title.hourlyDay") +
                            " " +
                            (this.APIusageByDay.date
                              ? this.APIusageByDay.date
                              : ""),
                          chartLabel: [
                            _vm.$t("chart.label.time"),
                            _vm.$t("chart.label.records"),
                          ],
                          avg: _vm.usageByDay_Avg,
                          chart: "hourlyDay",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "chartBarHourlyByDay chartArea noDataChart",
                    },
                    [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$t("chart.title.hourlyDay"))),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("home.nodata")))]),
                    ]
                  ),
            ]),
            _c("b-col", { staticClass: "p0", attrs: { xl: "4", lg: "6" } }, [
              _vm.NoDataTop4Toggle
                ? _c(
                    "div",
                    { staticClass: "chartArea chartBarAccount" },
                    [
                      _c("HorizontalBarChart", {
                        staticClass: "chart",
                        attrs: {
                          chartData: _vm.DATAaccountByDay,
                          chartTitle: [
                            _vm.$t("chart.title.top5Account"),
                            _vm.APIaccountByDay.date
                              ? _vm.APIaccountByDay.date
                              : "",
                          ],
                          chartLabel: [
                            _vm.$t("chart.label.records"),
                            _vm.$t("chart.label.account"),
                          ],
                          chart: "userRank",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "chartBarAccount chartArea noDataChart" },
                    [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$t("chart.title.top5Account"))),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("home.nodata")))]),
                    ]
                  ),
            ]),
            _c("b-col", { staticClass: "p0", attrs: { xl: "4", lg: "6" } }, [
              _vm.NoDataNMonthToggle
                ? _c(
                    "div",
                    { staticClass: "chartArea chartLineMonths" },
                    [
                      _c("LineChart", {
                        staticClass: "chart",
                        attrs: {
                          chartData: _vm.DATAtestResultByXMonth,
                          chartTitle: _vm.$t("chart.title.monthly"),
                          chartLabel: [
                            _vm.$t("chart.label.month"),
                            _vm.$t("chart.label.records"),
                          ],
                          chartPercen: _vm.toolTipsArrayPercen,
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "chooseMonthlyBox" },
                        [
                          _c("div", { staticClass: "chooseTimeType" }, [
                            _c("label", { staticClass: "timeType" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectTimeType,
                                    expression: "selectTimeType",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "month",
                                  disabled: _vm.loading,
                                },
                                domProps: {
                                  checked: _vm._q(_vm.selectTimeType, "month"),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      _vm.selectTimeType = "month"
                                    },
                                    _vm.fnChangeTimeType,
                                  ],
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("chart.label.month"))),
                            ]),
                            _c("label", { staticClass: "timeType" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectTimeType,
                                    expression: "selectTimeType",
                                  },
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "quarter",
                                  disabled: _vm.loading,
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.selectTimeType,
                                    "quarter"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      _vm.selectTimeType = "quarter"
                                    },
                                    _vm.fnChangeTimeType,
                                  ],
                                },
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("chart.label.quarter"))
                              ),
                            ]),
                          ]),
                          _c("b-spinner", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.drewing,
                                expression: "drewing",
                              },
                            ],
                            staticClass: "buttonLoad drawingLoad",
                            attrs: {
                              small: "",
                              label: "Loading...",
                              variant: "light",
                            },
                          }),
                          _c("div", { staticClass: "chooseTimePeriod" }, [
                            _c("span", { staticClass: "timePeriod" }, [
                              _vm._v(
                                _vm._s(_vm.$t("chart.label.timePeriod")) + ":"
                              ),
                            ]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectTimePeriod,
                                    expression: "selectTimePeriod",
                                  },
                                ],
                                staticClass: "timePeriod",
                                attrs: { disabled: _vm.loading },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectTimePeriod = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.reDrawMonthlyChart,
                                  ],
                                },
                              },
                              _vm._l(_vm.timePeriod, function (period, index) {
                                return _c(
                                  "option",
                                  {
                                    key: period,
                                    domProps: {
                                      selected: index === 0,
                                      value: period,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(period) + " ")]
                                )
                              }),
                              0
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "chartLineMonths chartArea noDataChart",
                      attrs: { lg: "4", md: "6" },
                    },
                    [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("chart.title.monthly")))]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("home.nodata")))]),
                    ]
                  ),
            ]),
            _c("b-col", { staticClass: "p0", attrs: { lg: "12", xl: "8" } }, [
              _c(
                "div",
                { staticClass: "chartDoughnut-group" },
                [
                  _c(
                    "b-col",
                    { staticClass: "p0", attrs: { md: "12", lg: "6" } },
                    [
                      _vm.NoDataPerMonthToggle
                        ? _c(
                            "div",
                            { staticClass: "chartDoughnutMonth" },
                            [
                              _c("DoughnutChart", {
                                staticClass: "chart",
                                attrs: {
                                  chartData: _vm.DATAtestResultByMonth,
                                  chartTitle:
                                    _vm.testResultByMonth_Title +
                                    " " +
                                    _vm.$t("chart.title.result"),
                                  labelData: [
                                    _vm.testResultByMonth_Total,
                                    _vm.testResultByMonthPercen,
                                  ],
                                  month: "",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "chartDoughnutMonth noDataChart" },
                            [
                              _c("h3", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.testResultByMonth_ErrorTitle) +
                                    " " +
                                    _vm._s(_vm.$t("chart.title.result")) +
                                    " "
                                ),
                              ]),
                              _c("p", [_vm._v(_vm._s(_vm.$t("home.nodata")))]),
                            ]
                          ),
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "p0", attrs: { md: "12", lg: "6" } },
                    [
                      _vm.NoDataTotalToggle
                        ? _c(
                            "div",
                            { staticClass: "chartDoughnutTotal" },
                            [
                              _c("DoughnutChart", {
                                staticClass: "chart",
                                attrs: {
                                  chartData: _vm.DATAtotalTestResult,
                                  chartTitle: _vm.$t("chart.title.total"),
                                  labelData: [_vm.totalTestResult_Total],
                                  total: "",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "chartDoughnutTotal noDataChart" },
                            [
                              _c("h3", [
                                _vm._v(_vm._s(_vm.$t("chart.title.total"))),
                              ]),
                              _c("p", [_vm._v(_vm._s(_vm.$t("home.nodata")))]),
                            ]
                          ),
                    ]
                  ),
                  _vm.NoDataTotalToggle
                    ? _c(
                        "ul",
                        { staticClass: "labelsBox totalChartLabel" },
                        _vm._l(_vm.labelResult, function (label, index) {
                          return _c(
                            "li",
                            { key: index, staticClass: "label" },
                            [
                              _c("span", {
                                staticClass: "labelColor",
                                style: { background: label.color },
                              }),
                              _c("p", [_vm._v(_vm._s(label.label))]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("b-col", { staticClass: "p0", attrs: { xl: "4", lg: "6" } }, [
              _vm.NoDataTestResultPerQuarter
                ? _c(
                    "div",
                    { staticClass: "chartArea chartPieType" },
                    [
                      _c("DoughnutChart", {
                        staticClass: "chart",
                        attrs: {
                          chartData: _vm.DATAtestType,
                          chartTitle: _vm.testResultPerQuarter_Title,
                          pie: "",
                        },
                      }),
                      _c(
                        "ul",
                        { staticClass: "pieLabelsBox" },
                        _vm._l(
                          _vm.testResultPerQuarter_Label,
                          function (label, index) {
                            return _c(
                              "li",
                              { key: index, staticClass: "label" },
                              [
                                _c("span", {
                                  staticClass: "labelColor",
                                  style: { background: label.color },
                                }),
                                _c("p", [_vm._v(_vm._s(label.label))]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "chartPieType chartArea noDataChart" },
                    [
                      _c("h3", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.APIchartPerQuarterErrorTitle) +
                            " " +
                            _vm._s(_vm.$t("chart.title.testType")) +
                            " "
                        ),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("home.nodata")))]),
                    ]
                  ),
            ]),
            _c("b-col", { staticClass: "p0", attrs: { xl: "4", lg: "6" } }, [
              _vm.NoDatabatteryTypeMonth
                ? _c(
                    "div",
                    { staticClass: "chartArea chartBarType" },
                    [
                      _c("BarChart", {
                        staticClass: "chart",
                        attrs: {
                          chartData: _vm.DATAbatteryTypeMonth,
                          chartTitle: _vm.$t("chart.title.batteryTypeMonth"),
                          chartLabel: [
                            _vm.$t("chart.label.batteryType"),
                            _vm.$t("chart.label.records"),
                          ],
                          chart: "batteryType",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "chartBarType chartArea noDataChart" },
                    [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$t("chart.title.batteryTypeMonth"))),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("home.nodata")))]),
                    ]
                  ),
            ]),
            _c("b-col", { staticClass: "p0", attrs: { xl: "4", lg: "6" } }, [
              _vm.NoDatabatteryTypeYear
                ? _c(
                    "div",
                    { staticClass: "chartArea chartPieType" },
                    [
                      _c("DoughnutChart", {
                        staticClass: "chart",
                        attrs: {
                          "chart-data": _vm.DATAbatteryTypeYear,
                          chartTitle:
                            (this.batteryTypeYear_Title
                              ? this.batteryTypeYear_Title
                              : "") +
                            " " +
                            _vm.$t("chart.title.batteryTypeYear"),
                          pie: "",
                        },
                      }),
                      _c(
                        "ul",
                        { staticClass: "pieLabelsBox" },
                        _vm._l(
                          _vm.batteryTypeYear_Label,
                          function (label, index) {
                            return _c(
                              "li",
                              { key: index, staticClass: "label" },
                              [
                                _c("span", {
                                  staticClass: "labelColor",
                                  style: { background: label.color },
                                }),
                                _c("p", [_vm._v(_vm._s(label.label))]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "chartPieType chartArea noDataChart" },
                    [
                      _c("h3", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.batteryTypeYear_Title) +
                            " " +
                            _vm._s(_vm.$t("chart.title.batteryTypeYear")) +
                            " "
                        ),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.$t("home.nodata")))]),
                    ]
                  ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("ul", { staticClass: "groupList" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }