var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "frame" },
    [
      _c(
        "div",
        { staticClass: "testReportBottom" },
        [
          _c("dataTable", {
            attrs: {
              columns: _vm.columns,
              device: "deviceManager",
              "data-test": "dataTable",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "deviceErrorCode" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t("deviceManagement.errorListTitle")))]),
        _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list1")))]),
        _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list2")))]),
        _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list3")))]),
        _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list4")))]),
        _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list5")))]),
        _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list6")))]),
        _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list7")))]),
        _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list8")))]),
        _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list9")))]),
      ]),
      _vm.subdomain !== "napa"
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "IOTDeviceToggle",
                centered: "",
                title: _vm.$t("modal.notice"),
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-footer",
                    fn: function ({ ok }) {
                      return [
                        _c(
                          "b-form-checkbox",
                          {
                            model: {
                              value: _vm.IOTcheck,
                              callback: function ($$v) {
                                _vm.IOTcheck = $$v
                              },
                              expression: "IOTcheck",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "modal.deviceManagement.deviceOnly.text2"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "m-button",
                          {
                            staticClass: "tableButton cancel",
                            attrs: { raised: "", dense: "" },
                            on: {
                              click: function ($event) {
                                ok(_vm.saveIOTcheck())
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1203696246
              ),
            },
            [
              _c("h6", [
                _vm._v(
                  _vm._s(_vm.$t("modal.deviceManagement.deviceOnly.text1"))
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }